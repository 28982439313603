<template>
  <section id="service-banner" class="service-banner desktop-banner">
    <VueAgile :options="slideOptions" v-if="!loadingBanner" v-vpshow-scroll>
      <div class="agile-carousel" v-for="(service, index) in services" :key="index">
        <img :src="service.image_desktop_jpg" :alt="service.title" class="agile-carousel-image" />
      </div>
      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
    </VueAgile>
    <PaxelLoader :loading="loadingBanner"></PaxelLoader>
  </section>
</template>

<script>
import { VueAgile } from 'vue-agile';
import PaxelLoader from '@/components/PaxelLoader.vue';

export default {
  name: 'DesktopServiceBanner',
  components: {
    VueAgile,
    PaxelLoader,
  },
  data() {
    return {
      services: [],
      loadingBanner: true,
      slideOptions: {
        navButtons: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        speed: 1500,
        fade: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: true,
            },
          },
          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
            },
          },
        ],
      },
    };
  },
  created() {
    this.$store.watch(
      (state) => state.paxelBoxServices,
      (newValue) => {
        this.loadingBanner = true;
        this.services = newValue;
        this.loadingBanner = false;
      },
    );
  },
};
</script>
<style>
.desktop-banner .agile__nav-button {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 80px;
}
.desktop-banner .agile__nav-button:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: #ffffff;
  opacity: 1;
}
.desktop-banner .agile__nav-button--prev {
  left: 0;
}
.desktop-banner .agile__nav-button--next {
  right: 0;
}
.desktop-banner .agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.desktop-banner .agile__dot {
  margin: 0 10px;
}
.desktop-banner .agile__dot button {
  background-color: #ffffff;
  border: 1px solid #5e50a1;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}
.desktop-banner .agile__dot.agile__dot--current > button {
  background-color: #5e50a1;
}
.desktop-banner .agile-carousel-image {
  display: block;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
</style>

export const LANGUAGE = 'lang';
export const OVERDUE = 'overdue';

const LocalStorage = {
  setLanguage(language) {
    return localStorage.setItem(LANGUAGE, language);
  },
  getLanguage() {
    return localStorage.getItem(LANGUAGE) || 'id';
  },
  setOverdue(overdue) {
    return localStorage.setItem(OVERDUE, overdue);
  },
  getOverdue() {
    return localStorage.getItem(OVERDUE);
  },
  removeOverdue() {
    return localStorage.removeItem(OVERDUE);
  },
};

export default LocalStorage;

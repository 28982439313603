<template>
  <section id="services" class="services container">
    <div class="section-caption">{{ $t("paxelbox_services") }}</div>
    <VueSlickCarousel
      v-bind="slickOptions"
      v-if="!loadingService && services.length > 0"
      v-vpshow-scroll="'slide-bottom'"
    >
      <div v-for="(service, index) in services" :key="index" class="card">
        <img
          class="card-img-top"
          v-bind:src="service.image_desktop_jpg"
          v-bind:alt="service.title"
        />
        <div class="card-body">
          <h5 class="card-title">{{ service.title }}</h5>
          <div class="card-text">{{ service.description | strippedTruncateContent(15) }}</div>
        </div>
        <div class="card-footer">
          <router-link tag="a" :to="`/services/#` + service.slug" class="bg-purple read-more-btn">
            {{ $t("read_more") }}
            <span class="arrow-right">
              <img src="@/assets/icons/arrow-right-white.svg" alt="Paxel" />
            </span>
          </router-link>
        </div>
      </div>
      <template #prevArrow="">
        <button class="slick-custom-arrow slick-custom-arrow-left">
          <i class="fas fa-chevron-left"></i>
        </button>
      </template>
      <template #nextArrow="">
        <button class="slick-custom-arrow slick-custom-arrow-right">
          <i class="fas fa-chevron-right"></i>
        </button>
      </template>
    </VueSlickCarousel>
    <div class="text-center" v-else-if="!loadingService && services.length == 0"></div>
    <PaxelLoader :loading="loadingService"></PaxelLoader>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import PaxelLoader from '@/components/PaxelLoader.vue';

export default {
  name: 'DesktopServices',
  components: {
    VueSlickCarousel,
    PaxelLoader,
  },
  data() {
    return {
      services: [],
      loadingService: true,
      slickOptions: {
        arrows: true,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.$store.dispatch('fetchPaxelBoxServices', {
      language: this.$store.getters.getLanguage,
      limit: 3,
    });
  },
  created() {
    this.$store.watch(
      (state) => state.paxelBoxServices,
      (newValue) => {
        this.loadingService = true;
        this.services = newValue;
        this.loadingService = false;
      },
    );
    this.$store.watch(
      (state) => state.language,
      (newValue) => {
        this.$store.dispatch('fetchPaxelBoxServices', { language: newValue, limit: 3 });
      },
    );
  },
};
</script>

<style scoped>
.desktop-home section .section-caption {
  padding-left: 15px;
}
.card {
  border: none;
  border-radius: 20px;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.card:hover {
  z-index: 1000;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}
.card > img {
  height: 224px;
  border-radius: 20px 20px 0px 0px;
  -webkit-transition: all 4s ease;
  -moz-transition: all 4s ease;
  -o-transition: all 4s ease;
  -ms-transition: all 4s ease;
  transition: all 4s ease;
}
.card:hover > img {
  opacity: 0.9;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.card-body {
  height: 200px;
  padding: 30px;
  overflow: hidden;
  background-color: #f9f9fb;
}
.card-body .card-title {
  font-family: "GothamMedium";
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #414042;
}
.card-body .card-text {
  font-family: "GothamBook";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 27px;
  letter-spacing: normal;
  color: #575758;
}
.card-footer:last-child {
  border: none;
  padding-bottom: 30px;
  background-color: #f9f9fb;
  border-radius: 0 0 20px 20px;
}
.card-footer a {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
  letter-spacing: 0.39px;
  border-radius: 0.25rem;
  text-align: center;
  display: block;
  width: 100%;
  color: #ffffff;
}
.card-footer a.read-more-btn:hover {
  background-color: #524494;
}
.card-footer a.read-more-btn:focus {
  border: none;
}
.card-footer a .arrow-right {
  float: right;
  padding-right: 20px;
  -webkit-transform: perspective(1px) translateZ(0);
  -moz-transform: perspective(1px) translateZ(0);
  -ms-transform: perspective(1px) translateZ(0);
  -o-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}
.card-footer a.read-more-btn:hover .arrow-right {
  -webkit-animation-name: wobble-horizontal-on-hover;
  animation-name: wobble-horizontal-on-hover;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.card-footer img {
  vertical-align: inherit;
}
.slick-custom-arrow {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  z-index: 1000;
}
.slick-custom-arrow:hover {
  color: #575758;
  opacity: 1;
}
.slick-custom-arrow:focus {
  outline: none;
}
.slick-custom-arrow-left {
  left: -25px;
}
.slick-custom-arrow-right {
  right: -25px;
}
.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}
@-webkit-keyframes wobble-horizontal-on-hover {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes wobble-horizontal-on-hover {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
</style>

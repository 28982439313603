<template>
  <div class="mobile-guest-book">
    <section id="guest-book" class="guest-book">
      <div class="guest-book-caption">
        <div class="container">
          {{ $t('guest_book') }}
        </div>
      </div>
      <div class="guest-book-form">
        <div class="container">
          <b-col sm="6">
            <b-overlay :show="loading" rounded="sm">
              <b-form @submit.stop.prevent="onSubmit">
                <b-form-group
                  id="sender-name-group"
                  :label="$t('sender_name')"
                  label-for="sender-name"
                >
                  <b-form-input
                    id="sender-name"
                    v-model.trim="form.sender_name"
                    type="text"
                    aria-describedby="input-sender-name-feedback"
                    :placeholder="$t('enter_sender_name')"
                    :class="{ 'is-invalid': $v.form.sender_name.$error }"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-sender-name-feedback">
                    {{ $t('sender_name') + " " + $t('should_not_empty') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      id="sender-contact-group"
                      :label="$t('sender_contact')"
                      label-for="sender-contact"
                    >
                      <b-form-input
                        id="sender-contact"
                        v-model.trim="form.sender_contact"
                        type="text"
                        aria-describedby="input-sender-contact-feedback"
                        :placeholder="$t('enter_sender_contact')"
                        :class="{ 'is-invalid': $v.form.sender_contact.$error }"
                        @input="enforcePhoneNumber"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="input-sender-contact-feedback" v-if="!$v.form.sender_contact.required">
                        {{ $t('sender_contact') + " " + $t('should_not_empty') }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        id="input-sender-contact-feedback" v-if="!$v.form.sender_contact.phone">
                        {{ $t('sender_contact') + " " +
                        $t('between_length', { min: minPhoneLength, max: maxPhoneLength}) }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      id="email-address-group"
                      :label="$t('email_address')"
                      label-for="email-address"
                    >
                      <b-form-input
                        id="email-address"
                        v-model.trim="form.email_address"
                        type="text"
                        aria-describedby="input-email-address-feedback"
                        :placeholder="$t('enter_email_address')"
                        :class="{ 'is-invalid': $v.form.email_address.$error }"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="input-email-address-feedback" v-if="!$v.form.email_address.required">
                        {{ $t('email_address') + " " + $t('should_not_empty') }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        id="input-email-address-feedback" v-if="!$v.form.email_address.email">
                        {{ $t('email_address') + " " + $t('not_valid') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group :label="$t('what_do_you_want')">
                  <b-form-radio
                    id="paxelbox-locker"
                    v-model.trim="form.purpose"
                    name="purpose"
                    value="paxelbox_locker"
                    :class="{ 'is-invalid': $v.form.purpose.$error }">
                      <div class="radio-caption">{{ $t('paxelbox_locker') }}</div>
                      <div class="radio-description">
                        {{ $t('about_services_and_partnership_with_paxelbox') }}
                      </div>
                    </b-form-radio>
                  <b-form-radio
                    id="paxelbox-ad"
                    v-model.trim="form.purpose"
                    name="purpose"
                    value="advertise_in_paxelbox"
                    :class="{ 'is-invalid': $v.form.purpose.$error }">
                      <div class="radio-caption">{{ $t('advertise_on_paxelbox') }}</div>
                      <div class="radio-description">{{ $t('place_your_ad_on_paxelbox') }}</div>
                    </b-form-radio>
                  <b-form-invalid-feedback
                    id="input-purpose-feedback">
                    {{ $t('you_should_choose_one') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="message-group"
                  :label="$t('message')"
                  label-for="message"
                >
                  <b-form-textarea
                    id="message"
                    v-model.trim="form.message"
                    rows="5"
                    max-rows="5"
                    aria-describedby="input-message-feedback"
                    :placeholder="$t('enter_your_message')"
                    :class="{ 'is-invalid': $v.form.message.$error }"
                  ></b-form-textarea>
                  <b-form-invalid-feedback
                    id="input-message-feedback">
                    {{ $t('message') + " " + $t('should_not_empty') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox
                    id="term-check"
                    v-model.trim="form.term_check"
                    name="term-check"
                    aria-describedby="input-term-check-feedback"
                    :class="{ 'is-invalid': $v.form.term_check.$error }"
                  >
                    <span class="term-check" v-html="$t('guest_book_term_check',
                        { url: `${revampWebURL}/help-and-support` })">
                    </span>
                  </b-form-checkbox>
                  <b-form-invalid-feedback
                    id="input-term-check-feedback" style="padding-left: 1.5rem;">
                    {{ $t('you_should_accept_terms_and_conditions') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="mt-4">
                  <b-button id="send-btn" type="submit" class="btn-block btn-lg send-btn">
                    {{ $t('send_and_download_white_paper') }}
                  </b-button>
                </b-form-group>
              </b-form>
            </b-overlay>
          </b-col>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import CustomValidators, { minPhone, maxPhone } from '@/validators';

export default {
  name: 'MobileGuestBookForm',
  data() {
    return {
      loading: false,
      minPhoneLength: minPhone,
      maxPhoneLength: maxPhone,
      form: {
        sender_name: '',
        sender_contact: '',
        email_address: '',
        purpose: '',
        business_type: this.$route.query.business_type,
        message: '',
        term_check: false,
      },
      revampWebBaseURL: process.env.VUE_APP_REVAMP_WEB,
    };
  },
  validations: {
    form: {
      sender_name: { required },
      sender_contact: { required, phone: CustomValidators.phoneValidate },
      email_address: { required, email },
      purpose: { required },
      message: { required },
      term_check: { sameAs: sameAs(() => true) },
    },
  },
  computed: {
    revampWebURL() {
      return `${this.revampWebBaseURL}/${i18n.locale}`;
    },
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch();
      this.$root.scrollToTop();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$nextTick(() => {
        this.loading = true;
      });

      this.$recaptcha('guestbook_submit').then((token) => {
        if (token) {
          const formData = new FormData();
          formData.append('sender_name', this.form.sender_name);
          formData.append('sender_contact', this.form.sender_contact);
          formData.append('sender_email', this.form.email_address);
          formData.append('requested_value', this.form.purpose);
          formData.append('message', this.form.message);
          formData.append('token', token);
          this.$store.dispatch('sendGuestBook', formData)
            .then(() => {
              this.$router.push({ name: 'guest-book-submitted' });
            })
            .catch((error) => {
              const message = error.response.data.message || i18n.t('something_wrong');
              this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: message,
              });
            });
        } else {
          const message = `Recaptcha Error: ${i18n.t('something_wrong')}`;
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
        }
      });

      setTimeout(() => {
        this.loading = false;
      },
      3000);
    },
    enforcePhoneNumber(value) {
      this.$nextTick(() => {
        this.form.sender_contact = value.replace(/\D/g, '');
      });
    },
  },
  created() {
    this.$root.scrollToAnchor('guest-book');
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      const recaptcha = this.$recaptchaInstance;
      recaptcha.showBadge();
    });
  },
  beforeDestroy() {
    const recaptcha = this.$recaptchaInstance;
    recaptcha.hideBadge();
  },
};
</script>

<style>
.mobile-guest-book .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5e50a1;
  background-color: #5e50a1;
}
.mobile-guest-book .guest-book-form .term-check > a {
  text-decoration: none;
  color: #5e50a1;
}
.mobile-guest-book .spinner-border {
  color: #5e50a1;
}
.mobile-guest-book .guest-book-form label,
.mobile-guest-book .guest-book-form legend {
  font-family: "GothamMedium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #424143;
}

.mobile-guest-book .guest-book-form input,
.mobile-guest-book .guest-book-form textarea,
.mobile-guest-book .guest-book-form input::placeholder,
.mobile-guest-book .guest-book-form textarea::placeholder {
  font-family: "GothamBook";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c4c7ca;
}
.mobile-guest-book .custom-control-label {
  margin-top: 10px;
}
.mobile-guest-book .custom-control-label::before,
.mobile-guest-book .custom-control-label::after {
    top: 25%;
    left: -1.53rem;
}
</style>
<style scoped>
.mobile-guest-book .guest-book-caption {
  font-family: "GothamMedium";
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 41px;
  letter-spacing: normal;
  color: #5e51a1;
  padding: 25px 0px 22px 0px;
}
.mobile-guest-book .guest-book-form {
  padding: 60px 0px;
  background-color: #f9f9fb;
}
.mobile-guest-book .guest-book-form label,
.mobile-guest-book .guest-book-form legend {
  font-family: "GothamMedium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #424143;
}
.mobile-guest-book .guest-book-form input,
.mobile-guest-book .guest-book-form textarea,
.mobile-guest-book .guest-book-form input::placeholder,
.mobile-guest-book .guest-book-form textarea::placeholder {
  font-family: "GothamBook";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c4c7ca;
}
.mobile-guest-book .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5e50a1;
  background-color: #5e50a1;
}
.mobile-guest-book .custom-control-label {
  margin-top: 10px;
}
.mobile-guest-book .custom-control-label::before,
.mobile-guest-book .custom-control-label::after {
    top: 25%;
    left: -1.53rem;
}
.mobile-guest-book .guest-book-form .radio-caption {
  font-family: "GothamMedium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #424143;
  padding-bottom: 5px;
}
.mobile-guest-book .guest-book-form .radio-description {
  font-family: "GothamBook";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #929293;
  padding-bottom: 10px;
}
.mobile-guest-book .guest-book-form .term-check {
  font-family: "GothamBook";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: #414042;
}
.mobile-guest-book .guest-book-form .term-check > a {
  text-decoration: none;
  color: #5e50a1;
}
.mobile-guest-book .guest-book-form .send-btn {
  font-family: "GothamMedium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #5e50a1;
  border: none;
}
.mobile-guest-book .guest-book-form .send-btn:focus {
  outline: none;
  box-shadow: none;
}
.mobile-guest-book .spinner-border {
  color: #5e50a1;
}
</style>

<template>
  <section id="location" class="location" v-vpshow-scroll>
    <div class="container-custom">
      <div class="location-wrapper row">
        <div class="location-text col-md-4">
          <div class="location-caption">
            {{ $t("location_caption") }}
          </div>
          <div class="location-description" v-vpshow-scroll="'slide-left'">
            {{ $t("location_description") }}<b>{{ $t("and_still_growing") }}</b>
          </div>
        </div>
        <div class="location-map col-md-8">
          <iframe
            :src="embeddedMapUrl"
            height="480"
            width="100%"
            frameborder="0"
            v-vpshow-scroll="'slide-right'"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DesktopLocation',
  data() {
    return {
      embeddedMapUrl: process.env.VUE_APP_EMBEDDED_MAP,
    };
  },
};
</script>

<style scoped>
.location {
  background-color: #f9f9fb;
  border-radius: 20px 0px 0px 20px;
}
.location .location-wrapper {
  margin-left: 0px;
  margin-right: 0px;
}
.location .location-text {
  padding: 60px 70px 60px 15px;
}
.location .location-caption {
  font-family: "GothamBold";
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 48px;
  letter-spacing: normal;
  margin-bottom: 32px;
  color: #5e51a1;
}
.location .location-description {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
}
.location .location-map {
  padding: 0px;
}
.container-custom {
  width: 100%;
  padding-right: 0px;
  padding-left: 15px;
}
@media (min-width: 576px) {
  .container-custom {
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container-custom {
    padding-left: calc(50vw - 360px);
  }
}
@media (min-width: 992px) {
  .container-custom {
    padding-left: calc(50vw - 480px);
  }
}
@media (min-width: 1200px) {
  .container-custom {
    padding-left: calc(50vw - 570px);
  }
}
</style>

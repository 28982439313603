<template>
  <section id="service-content" class="service-content mobile-services-content">
    <div v-for="(service, index) in services" :key="index" :id="service.slug">
      <div class="section-description-container">
        <div class="container">
          <div class="section-caption-m">{{ service.title }}</div>
          <div class="section-content-m"
            v-html="$options.filters.convertEmbedYoutube(service.description)">
          </div>
        </div>
        <div v-if="service.video_url !== ''" class="section-video">
          <div class="w-100">
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="getVideoUrl(service.video_url)"
              allowfullscreen
            ></b-embed>
          </div>
        </div>
      </div>
      <div class="section-howto-container">
          <VueSlickCarousel
            v-bind="slickOptions"
            v-if="!loadingService && service.guides.length > 0"
            v-vpshow-scroll>
            <div class="agile-carousel" v-for="(guide, idx) in service.guides" :key="idx">
              <div class="container" style="marginTop: 10px;">
                <img :src="guide.image" class="img-guidemobile img-fluid" />
                <div class="how-to-use">{{ $t('how_to_use') }}</div>
                <div class="tab-title-container">
                  <span class="tab-numbers">{{ idx + 1 }}</span>
                  <span class="tab-titles" v-html="guide.description"></span>
                </div>
              </div>
            </div>
            <template #prevArrow="">
              <button class="slick-custom-arrow slick-custom-arrow-left">
                <i class="fas fa-chevron-left"></i>
              </button>
            </template>
            <template #nextArrow="">
              <button class="slick-custom-arrow slick-custom-arrow-right">
                <i class="fas fa-chevron-right"></i>
              </button>
            </template>
          </VueSlickCarousel>
      </div>
      <div class="section-download-container"
        v-if="service.download_button.play_store_icon != '' &&
              service.download_button.app_store_icon != ''">
        <div class="container">
          <div class="section-downloads">
            <div class="download-text">{{ $t('download_paxel_app') }}</div>
            <div class="col-md-6">
              <b-row>
                <b-col class="img-playstore">
                  <a
                  :href="service.download_button.play_store_url"
                  v-if="service.download_button.play_store_icon != ''">
                    <img :src="service.download_button.play_store_icon"
                      :alt="service.title" class="img-fluid" />
                  </a>
                </b-col>
                <b-col class="img-appstore">
                  <a
                    :href="service.download_button.app_store_url"
                    v-if="service.download_button.app_store_icon != ''">
                    <img :src="service.download_button.app_store_icon"
                      :alt="service.title" class="img-fluid" />
                  </a>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PaxelLoader :loading="loadingService"></PaxelLoader>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import PaxelLoader from '@/components/PaxelLoader.vue';

export default {
  name: 'MobileServiceContent',
  components: {
    PaxelLoader,
    VueSlickCarousel,
  },
  data() {
    return {
      services: [],
      serviceIndexes: [],
      loadingService: true,
      slideOptions: {
        navButtons: true,
        dots: false,
        fade: true,
        infinite: false,
      },
      slickOptions: {
        arrows: true,
        focusOnSelect: true,
        infinite: false,
        dots: false,
        swipe: false,
        fade: true,
      },
    };
  },
  mounted() {
    this.$store.dispatch('fetchPaxelBoxServices', {
      language: this.$store.getters.getLanguage,
      limit: -1, // all data
    });
  },
  created() {
    this.$store.watch(
      (state) => state.paxelBoxServices,
      (newValue) => {
        this.loadingService = true;
        this.services = newValue;
        this.serviceIndexes = newValue.map(() => ({ currentTab: 0 }));
        this.loadingService = false;
      },
    );
    this.$store.watch(
      (state) => state.language,
      (newValue) => {
        this.$store.dispatch('fetchPaxelBoxServices', { language: newValue, limit: 3 });
      },
    );
  },
  methods: {
    // nextTab(index, guides) {
    //   if (this.serviceIndexes[index].currentTab < guides.length - 1) {
    //     this.serviceIndexes[index].currentTab += 1;
    //   }
    // },
    // prevTab(index) {
    //   if (this.serviceIndexes[index].currentTab > 0) {
    //     this.serviceIndexes[index].currentTab -= 1;
    //   }
    // },
    // isFirstTab(index) {
    //   return this.serviceIndexes[index].currentTab === 0;
    // },
    // isLastTab(index, guides) {
    //   return this.serviceIndexes[index].currentTab === guides.length - 1;
    // },
    getVideoUrl(url) {
      let videourl = url;
      const pattern = /^((http|https):\/\/)/;
      if (!pattern.test(url)) {
        videourl = `http://${url}`;
      }
      return videourl;
    },
  },
};
</script>
<style>
  .mobile-services-content .agile__nav-button {
    background: transparent;
    border: none;
    color: #5e50a1;
    cursor: pointer;
    font-size: 24px;
    height: 280px;
    position: absolute;
    top: 0;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    width: 80px;
  }
  .mobile-services-content .agile__nav-button--prev {
    left: 0;
  }
  .mobile-services-content .agile__nav-button--next {
    right: 0;
  }
</style>
<style scoped>
  .section-caption-m {
    font-family: "GothamBold";
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: 0.21px;
    color: #5e50a1;
    margin-bottom: 30px;
  }

  .section-content-m {
    font-family: "GothamBook";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #424143;
    margin-bottom: 20px;
  }

  .img-guidemobile {
    width: 100%;
    height: 280px;
    margin: 0 0 20px;
    padding: 20px 0;
    object-fit: contain;
  }

  .tab-title-container {
    display: flex;
    flex-wrap: nowrap;
    padding: 0px 40px;
    height: 60px;
    max-height: 100px;
  }

  .tab-numbers {
    font-family: "GothamBold";
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5e50a1;
    border-radius: 40px;
    background-color: transparent;
    padding: 5px 15px;
    border: 2px solid #5e50a1;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .tab-titles {
    font-family: "GothamBook";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    align-self: center;
  }

  .tab-titles > :last-child {
    margin: 0;
  }

  .section-description-container .container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .section-video {
    margin-bottom: 20px;
  }

  .section-howto-container {
    padding: 0px 0px 30px 0px;
    margin: 0px 0px 30px 0px;
    background-color: #f9f9fb;
  }

  .how-to-use {
    font-family: "GothamBold";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.88px;
    text-align: center;
    color: #5e50a1;
    margin-bottom: 18px;
  }

  .section-download-container {
    padding: 0px 0px 30px 0px;
    text-align: center;
  }

  .section-download-container .download-text {
    font-family: "GothamBold";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.88px;
    text-align: center;
    color: #5e50a1;
    padding-bottom: 25px;
  }

  .section-download-container .download-icon a {
    padding: 0px 15px;
  }

  .slick-custom-arrow {
    background: transparent;
    border: none;
    color: #5e50a1;
    cursor: pointer;
    font-size: 24px;
    height: 280px;
    position: absolute;
    top: 0;
    transition-duration: 0.3s;
    z-index: 1000;
  }
  .slick-disabled {
    opacity: 0.3;
  }
  .slick-custom-arrow:focus {
    outline: none;
  }
  .slick-custom-arrow-left {
    left: 26.5px;
  }
  .slick-custom-arrow-right {
    right: 26.5px;
  }
</style>

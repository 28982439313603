<template>
  <section id="paxelbox" class="paxelbox">
    <VueAgile :options="slideOptions" v-if="!loadingBanner" v-vpshow-scroll>
      <div class="agile-carousel" v-for="(banner, index) in banners" :key="index">
        <div class="container">
          <div class="agile-carousel-caption">
            <h3 class="caption">{{ banner.header }}</h3>
            <div class="text" v-html="banner.sub_heading"></div>
            <div class="cta-button mt-2"
              v-if="banner.button.article != null ||
                    banner.button.other_url != ''">
              <a :href="ctaURL(banner.button)" class="cta-link-btn">
                {{ banner.button.text_button }}
              </a>
            </div>
            <div class="download-button mt-2"
              v-if="banner.download_button.app_store_icon != '' ||
                    banner.download_button.play_store_icon != ''">
              <a target="_blank" :href="banner.download_button.play_store_url">
                <img
                  :src="banner.download_button.play_store_icon"
                  alt="Paxel App"
                  class="img-fluid mt-2"
                >
              </a>
              <a target="_blank" :href="banner.download_button.app_store_url" class="px-2">
                <img
                  :src="banner.download_button.app_store_icon"
                  alt="Paxel App"
                  class="img-fluid mt-2"
                >
              </a>
            </div>
          </div>
        </div>
        <img :src="banner.image_desktop_jpg" :alt="banner.header" class="agile-carousel-image" />
      </div>
      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
    </VueAgile>
    <PaxelLoader :loading="loadingBanner"></PaxelLoader>
  </section>
</template>

<script>
import { VueAgile } from 'vue-agile';
import i18n from '@/plugins/i18n';
import PaxelLoader from '@/components/PaxelLoader.vue';

export default {
  name: 'DesktopPaxelBox',
  components: {
    VueAgile,
    PaxelLoader,
  },
  data() {
    return {
      banners: [],
      loadingBanner: true,
      slideOptions: {
        navButtons: false,
        autoplay: false,
        autoplaySpeed: 5000,
        speed: 2500,
        fade: true,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        timing: 'ease-in-out',
        changeDelay: 300,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: false,
            },
          },
          {
            breakpoint: 900,
            settings: {
              navButtons: false,
              dots: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    ctaURL(button) {
      const url = button.is_article
        ? `${this.$root.revampWebBaseURL}/${i18n.locale}/news-and-promos/detail/${button.article.created_at_date_only}/${button.article.slug}`
        : button.other_url;
      return url;
    },
  },
  mounted() {
    this.$store.dispatch('fetchPaxelBoxBanners', {
      language: this.$store.getters.getLanguage,
      limit: 1,
    });
  },
  created() {
    this.$store.watch(
      (state) => state.paxelBoxBanners,
      (newValue) => {
        this.loadingBanner = true;
        this.banners = newValue;
        this.loadingBanner = false;
      },
    );
    this.$store.watch(
      (state) => state.language,
      (newValue) => {
        this.$store.dispatch('fetchPaxelBoxBanners', { language: newValue, limit: 1 });
      },
    );
  },
};
</script>

<style>
.desktop-home .agile__nav-button {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 80px;
}
.desktop-home .agile__nav-button:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: #ffffff;
  opacity: 1;
}
.desktop-home .agile__nav-button--prev {
  left: 0;
}
.desktop-home .agile__nav-button--next {
  right: 0;
}
.desktop-home .agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.desktop-home .agile__dot {
  margin: 0 10px;
}
.desktop-home .agile__dot button {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}
.desktop-home .agile__dot--current button,
.desktop-home .agile__dot:hover button {
  background-color: #ffffff;
}
.desktop-home .agile-carousel-image {
  display: block;
  height: 700px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.desktop-home .agile-carousel-caption {
  position: absolute;
  right: auto;
  top: 15%;
  width: 390px;
  left: auto;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  color: #ffffff;
}
.desktop-home .agile-carousel-caption .caption {
  font-family: "GothamBold";
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: 0.36px;
  color: #ffffff;
  margin-bottom: 20px;
  width: 300px;
}
.desktop-home .agile-carousel-caption .text {
  font-family: "GothamMedium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 27px;
  letter-spacing: normal;
  color: #ffffff;
}
</style>
<style scoped>
.desktop-home .cta-button {
  display: flex;
}
.desktop-home .cta-button a {
  font-family: "GothamBold";
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border: none;
  padding: 15px 50px;
  letter-spacing: normal;
  border-radius: 0.25rem;
  text-align: center;
  color: #424143;
  background-color: #ffffff;
}
.desktop-home .cta-button a.cta-link-btn:focus {
  border: none;
}
.desktop-home .cta-button a.cta-link-btn:hover {
  opacity: 0.9;
}
</style>

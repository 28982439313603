// mutations
const mutations = {
  setLanguage(state, language) {
    state.language = language;
  },
  setPaxelBoxBanners(state, banners) {
    state.paxelBoxBanners = banners;
  },
  setPaxelBoxServices(state, services) {
    state.paxelBoxServices = services;
  },
  setGuestBook(state, guestBook) {
    state.guestBook = guestBook;
  },
  setAvailableLockers(state, availableLockers) {
    state.availableLockers = availableLockers;
  },
  setPackageLocker(state, packageLocker) {
    state.packageLocker = packageLocker;
  },
  setShortener(state, shortener) {
    state.shortener = shortener;
  },
  setOverdueDropOffDetail(state, overdueDropOffDetail) {
    state.overdueDropOffDetail = overdueDropOffDetail;
  },
  setOverdueDropOff(state, overdueDropOff) {
    state.overdueDropOff = overdueDropOff;
  },
};

export default mutations;

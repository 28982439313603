import axios from 'axios';

let tokenPaymentAPI = null;
const TokenPaymentAPIService = {
  init(url, clientId, clientSecret) {
    tokenPaymentAPI = axios.create({
      baseURL: url,
      timeout: 60000,
      params: {
        client_id: clientId,
        client_secret: clientSecret,
      },
    });
  },
  get(resource) {
    return tokenPaymentAPI.get(resource);
  },
};

export default TokenPaymentAPIService;

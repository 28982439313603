<!-- eslint-disable -->
<template>
    <b-container class="text-12">
        <div class="card border-0">
            <img src="@/assets/images/img-success-package.svg" class="img-fluid mx-auto d-block">
        </div>
        <div class="py-3 text-center">
      <h5 class="text-purple text-gotham-bold text-21">Pembayaran Berhasil!</h5>
    </div>
    <div class="text-center">
        <img :src="`${qrCode}`" class="img-fluid" alt="qrcode">
    </div>
    <div class="text-center">
      <p style="padding-left: 10px; padding-right: 10px;">
        Kami mengirimkan kode QR ke nomor telepon (via Whatsapp) dan alamat email yang kamu isi.
      </p>
    </div>
    <div class="text-center text-gotham-bold text-18 ">
      <p>
        Nomor Pintu: {{ this.overdueDropOff !== null ? this.mouthNumber : '-'}}
      </p>
    </div>
    <div class="mx-auto card border-0 bg-gray-light text-purple text-gotham-bold text-12 mb-2">
      <div class="mx-auto p-2 text-center">
        <p style="margin-bottom: 2px;">PERHATIAN:</p>
        <p style="margin-bottom: 2px;">Kode QR hanya dapat dipindai 1 (satu) kali saja. Pastikan kamu sudah mengambil barang di loker sebelum menutup pintu.</p>
      </div>
    </div>
    <div class="text-center">
      <p style="padding-left: 10px; padding-right: 10px; margin-bottom: 2px;">
        Berlaku sampai dengan:
      </p>
      <p style="padding-left: 10px; padding-right: 10px;" class="text-gotham-bold text-14">
        {{ this.overdueDropOff !== null ? epochToDateTime(this.times) : '-' }}
      </p>
    </div>
    <div class="text-center pb3">
      <p style="padding-left: 10px; padding-right: 10px;">
        Butuh bantuan? Silakan hubungi <span class="text-gotham-bold text-purple" style="cursor: pointer" @click="contactHera(heraUrl)">HERA</span>
      </p>
    </div>
    <div class="text-center text-14">
      <p style="padding-left: 10px; padding-right: 10px;" class="text-gotham-bold">
        Download Aplikasi Paxel
      </p>
    </div>
    <div>
      <div class="row">
        <div class="col text-center">
            <div>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.paxel&amp;referrer=utm_source%3Dwebrevamp%26utm_medium%3Dbadge">
                  <img
                    src="@/assets/images/download-play.png"
                    width="160"
                    alt="Paxel App"
                    class="img-fluid mt-2"
                  >
                </a>
            </div>
            <div>
                <a target="_blank" href="https://apps.apple.com/app/apple-store/id1372668166?pt=118967865&amp;ct=webrevamp&amp;mt=8">
                <img
                  src="@/assets/images/download-app.png"
                  width="160"
                  alt="Paxel App"
                  class="img-fluid mt-2"
                >
                </a>
            </div>
        </div>
      </div>
    </div>
    </b-container>
</template>
<script>
export default {
  data() {
    return {
      qrCode: '',
      mouthNumber: '',
      times: 0,
      pakpoboxAPIUrl: process.env.VUE_APP_PAKPOBOX_API,
      heraUrl: process.env.VUE_APP_HERA_LINK,
    };
  },
  beforeMount() {
    // checking query paramater overduepayment
    const paramOrderId = this.$route.query.orderid;
    if (paramOrderId !== undefined) {
      this.$store.dispatch('getOverdueDropoffDetail', paramOrderId)
        .then((response) => {
          if (response.id) {
            this.qrCode = `${this.pakpoboxAPIUrl}/ebox/api/v1/barcode/paxel/${response.barcode.id}`;
            this.mouthNumber = response.mouth.number;
            this.times = response.overdueTime;
          }
        })
        .catch(() => {
          // this.redirectToPaxelbox();
        });
    }
  },
  mounted() {
    if (this.overdueDropOff !== null) {
      this.qrCode = `${this.pakpoboxAPIUrl}/ebox/api/v1/barcode/paxel/${this.overdueDropOff.barcode.id}`;
      this.mouthNumber = this.overdueDropOff.mouth.number;
      this.times = this.overdueDropOff.overdueTime;
    } else {
      this.redirectToPaxelbox();
    }
  },
  computed: {
    overdueDropOff() {
      return this.$store.state.overdueDropOffDetail;
    },
  },
  methods: {
    redirectToPaxelbox() {
      this.$router.push({ path: '/' });
    },
    contactHera(url) {
      window.location.href = url;
    },
  },
};
</script>

<!-- eslint-disable -->
<template>
	<b-container class="text-12 text-gotham-medium w-50 m-width text-14 container">
		<PaxelLoader :loading="loadingIcon" style="
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);">
		</PaxelLoader>
		<div style="height: 70px;margin-bottom: -30px;margin-top: -30px;z-index: -1;position: relative;">
			<img  src="@/assets/images/img_background_lightPurple.png" style="width: 100%;z-index: -1;max-height:200px" />
		</div>
		<div v-if="!loadingIcon"> 
			<div class="order-card">
				<div class="px-4 py-2">
					<div class="py-2 text-gotham-bold" style="font-size: 16px;">
						{{ this.addressMain ? this.addressMain : '-' }}
					</div>
					<div class="text-gotham text-left" style="font-size: 12px;line-height: 16px;">
						{{ this.addressDetail ? this.addressDetail : '-' }}
					</div>
					<div class="py-2 " v-if="mapUrl">
						<span @click="openMapLink" class="text-purple text-gotham-bold text-left" style="font-size: 12px;line-height: 16px;color: #5e51a1;">{{ language == 'en' ? 'See map' : 'Lihat peta' }} > </span>
					</div>
					<div class="py-2 text-gotham text-left" style="font-size: 12px;line-height: 16px;">
						{{ this.addressAdditionalDetail ? this.addressAdditionalDetail : '-' }}
					</div>
					<div class="py-2 text-gotham text-left" style="font-size: 12px;line-height: 16px;white-space:pre-wrap">
						<div>{{ language == 'en' ? 'Operational Hours' : 'Jam Operasional' }} : </div>
						<div class="mt-2">{{ this.operationalHour ? this.operationalHour : '-' }}</div>
					</div>
					<div class="row px-3">
						<div class="" style="height: 120px;width: 50%;max-width: 200px;">
							<img v-if="customerPhoto" :src="customerPhoto" class="image-fit" @click="show(customerPhoto)" @error="imageUrlNoContent" alt="Locker photo"/>
						</div>
					</div>
				</div>
			</div>
	
			<!-- QR CODE-->
			<!-- only show on status IN_STORE-->
			<div class="order-card-top mt-3" v-if="status == 'IN_STORE'">
				<div class="image-fit p-4" style="height: 268px;width: 268px;display: flex;margin: auto;">
					<img v-if="qrCode" :src="qrCode" class="image-fit" @click="show(qrCode)" @error="imageUrlNoContent" alt="Qr code"/>
				</div>
				<div class="text-center">
					<label class="text-gotham" style="font-size: 14px">{{ language == 'en' ? 'Door Number' : 'Nomor Pintu' }} : <b>{{ this.doorNumber ? this.doorNumber : '-'}}</b></label>
				</div>
			</div>
			<div class="" :class="(status == 'IN_STORE') ? 'order-card-bottom' : 'order-card mt-3'">
				<div class="card-body">
					<div class="text-gotham">
						<label class="text-gotham text-12">Status:</label>
					</div>
					<div class="text-gotham-bold status-text-yellow" v-if="status == 'IN_STORE'">
						<label>{{ language == 'en' ? 'Package inside locker' : 'Paket di dalam Loker' }}</label>
					</div>
					<div class="text-gotham-bold status-text-purple" v-else-if="status == 'OPERATOR_TAKEN' || status == 'OPERATOR_PICK'">
						<label>{{ language == 'en' ? 'Taken by operator' : 'Paket diambil Operator Paxel' }}</label>
					</div>
					<div class="text-gotham-bold status-text-purple" v-else-if="status == 'TAKEN_BY_SYSTEM'">
						<label>{{ language == 'en' ? 'Taken by system' : 'Paket diambil Oleh Sistem' }}</label>
					</div>
					<div class="text-gotham-bold status-text-green" v-else-if="status == 'CUSTOMER_TAKEN'">
						<label>{{ language == 'en' ? 'Already picked up' : 'Paket sudah diambil' }}</label>
					</div>
					<div class="text-gotham-bold status-text-purple" v-else>
						<label>-</label>
					</div>
					<div class="text-gotham mt-2">
						<label>{{ language == 'en' ? 'Pickup deadline' : 'Batas akhir pengambilan' }}:</label>
					</div>
					<div class="text-gotham-bold text-14">
						<label>{{ this.deadlineDate ? epochToDateTimeLocalFormat(this.deadlineDate) : '-' }}</label>
					</div>
				</div>
				<div class="card-footer bg-white">
					<div class="row" style="display:flex;align-items:center;">
						<div class="col">
							<div class="">
								<label>{{ language == 'en' ? 'Order Number' : 'No. Order' }}:</label>
							</div>
							<div>
								<label class="uppercase text-gotham-bold text-14">{{ this.orderNumber ? this.orderNumber : '-' }}</label>
							</div>
						</div>
						<div class="col text-right">
							<button class="btn btn-primary text-white rounded-pill text-12 text-gotham-bold py-2 px-3"
								style="line-height: 12px;" @click="copy_to_clipboard(orderNumber)" v-if="orderNumber">{{ language == 'en' ? 'Copy' : 'Salin' }}</button>
						</div>
					</div>
				</div>
			</div>
	
			<!-- How to pickup package-->
			<div class="bg-white p-4" v-if="status == 'IN_STORE'">
				<div class="row my-2">
					<label class="text-gotham-bold text-14">{{ language == 'en' ? 'How to pick up package in PaxelBox' : 'Cara ambil paket di loker Paxel' }}:</label>
				</div>
				<div class="row my-2" style="display:flex;align-items:center;">
					<button class="btn btn-primary text-white rounded-circle text-12 text-gotham-bold">1</button>
					<span class="text-gotham text-12 ml-2">{{ language == 'en' ? 'Scan this QR Code at the locker' : 'Scan kode QR ini di loker' }}</span>
				</div>
				<div class="row my-2" style="display:flex;align-items:center;">
					<button class="btn btn-primary text-white rounded-circle text-12 text-gotham-bold">2</button>
					<span class="text-gotham text-12 ml-2">{{ language == 'en' ? 'The door to locker number' : 'Pintu loker nomor' }} <b>{{ this.doorNumber ? this.doorNumber : '-' }}</b> {{ language == 'en' ? 'will open' : 'akan terbuka' }} </span>
				</div>
				<div class="row my-2" style="display:flex;align-items:center;">
					<button class="btn btn-primary text-white rounded-circle text-12 text-gotham-bold">3</button>
					<span class="text-gotham text-12 ml-2">{{ language == 'en' ? 'Take your package and close the locker door' : 'Ambil paketmu dan tutup kembali pintu loker' }}</span>
				</div>
			</div>
			<!-- Attention-->
			<div class="mx-2" style="
				border-radius: 6px;
				background-color: #F3F2F8;" v-if="status == 'IN_STORE'">
				<div class="px-4 py-2">
					<div class="text-center text-gotham-bold text-14 text-purple" style="line-height: 20px;">{{ language == 'en' ? 'ATTENTION' : 'PERHATIAN' }}:</div>
					<label class="text-center text-gotham-bold text-14 text-purple" style="line-height: 20px">{{ language == 'en' ? 'This QR code can only scanned 1(one) time. Make sure your items are stored in the locker before closing the door.' : 'Kode Qr ini hanya dapat discan 1 (satu) kali saja. Pastikan kamu sudah mengambil barang di loker sebelum menutup pintu.' }}</label>
				</div>
			</div>
	
			<!-- Hera-->
			<div class="text-center pb3" v-if="yellowai == 'enabled'">
				<p style="padding-left: 10px; padding-right: 10px;margin-top: 24px;padding-bottom: 120px;">
					{{ language == 'en' ? 'Need help? Please' : 'Butuh bantuan? Silakan' }} <span class="text-gotham-bold text-purple" style="cursor: pointer"
						@click="clickContactSupport()">{{ language == 'en' ? 'contact HERA' : 'hubungi HERA' }}</span>
				</p>
			</div>
		</div>

	</b-container>
</template>
<script>
/* eslint-disable */

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import Vue from 'vue';
import i18n from '@/plugins/i18n';
import PaxelLoader from '@/components/PaxelLoader.vue';


Vue.use(VueViewer);
export default {
	components: { PaxelLoader },
  data() {
    return {
      pakpoboxAPIUrl: process.env.VUE_APP_PAKPOBOX_API,
      heraUrl: process.env.VUE_APP_HERA_LINK,
      addressMain: '',
      addressDetail: '',
      addressAdditionalDetail: '',
      mapUrl: '',
      operationalHour: '',
      customerPhoto: '',
      qrImage: '',
      doorNumber: '',
      status: '',
      deadlineDate: '',
      orderNumber: '',
      qrCode: '',
      images: [],
			loadingIcon: true,
			yellowai:""
    };
  },
  beforeMount() {
		//this function is for replacement computed on vue, because value call on vanila js not vue
		window.addEventListener('yellowai-key-localstorage-changed', (event) => {
			this.yellowai = event.detail.storage;
		});
		this.yellowai = localStorage.getItem('yellowai')
		this.validateUser()
    // checking query paramater order
    const paramOrderId = this.$route.query.orderid;
    if (paramOrderId !== undefined) {
			this.loadingIcon = true;
      this.$store.dispatch('getOrderDetail', paramOrderId)
        .then((res) => {
					this.orderNumber = res.expressNumber;
					this.addressMain = res.lockerLocationDetail.location;
					this.addressDetail = res.lockerLocationDetail.address;
					this.addressAdditionalDetail = res.lockerLocationDetail.description;
					this.customerPhoto = res.lockerLocationDetail.photo;
					this.mapUrl = res.lockerLocationDetail.mapsLink;
					this.operationalHour = res.lockerLocationDetail.operationalHour;
					this.doorNumber = res.mouth.number;
					this.deadlineDate = res.overdueTime;
					this.status = res.status;
					this.qrCode = `${this.pakpoboxAPIUrl}/ebox/api/v1/barcode/paxel/${res.barcode.id}`;
					this.loadingIcon = false;
        })
        .catch((error) => {
					this.loadingIcon = false;
          const message = error.response.data.message || i18n.t('something_wrong');
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
        });
    } else {
			this.loadingIcon = false;
      this.redirectToPaxelbox();
    }
  },
  methods: {
    onHide() {
      if (this.$route.query.preview) {
        this.$router.go(-1);
      }
    },
    show(imageUrl) {
      this.$router.push(`${this.$route.fullPath}&preview=true`);
      this.$viewerApi({
        options: {
          button: false,
          navbar: false,
          title: false,
          toolbar: false,
          tooltip: false,
          movable: false,
          zoomable: true,
          rotatable: false,
          scalable: true,
          transition: true,
          fullscreen: true,
          keyboard: false,
          hidden: (e) => {
            this.onHide(e);
          },
        },
        images: [imageUrl],
      });
    },
    redirectToPaxelbox() {
      this.$router.push({ path: '/' });
    },
		openMapLink() {
			if (typeof MobileJS != "undefined") {
				MobileJS.openGmapLink(JSON.stringify({ url: this.mapUrl }));
			} else if (typeof webkit != "undefined") {
				try {
					webkit.messageHandlers.openGmapLink.postMessage(JSON.stringify({ url: this.mapUrl }));
				} catch (err) {
					console.log("The native context does not exist yet");
				}
			} else {
				window.open(this.mapUrl, '_blank');
			}
		},
		clickContactSupport() {
			if (typeof MobileJS != 'undefined') {
				MobileJS.contactSupport(JSON.stringify({ title: 'Yellow AI' }));
			} else if (typeof webkit != 'undefined') {
				try {
					webkit.messageHandlers.contactSupport.postMessage(JSON.stringify({ title: 'Yellow AI' }));
				} catch (err) {
					console.log('The native context does not exist yet');
				}
			} else {
				window.location.href = this.heraUrl;
			}
		},
		validateUser() {
			let data_payload = {
				"token": this.$route.query.token
			}
			if (this.$route.query.token){
				this.$store.dispatch("validateUser", data_payload)
			}
		}
  },
  watch: {
    $route(val) {
      if (!val.query.preview) {
        const viewer = document.getElementsByClassName('viewer-canvas')?.[0];
        if (viewer) {
          viewer.click();
        }
      }
    },
  },
};
</script>

<style>
.order-card {
	border: 1px solid #EBEAF3;
	border-radius: 8px;
	padding: 1px;
}

.order-card-bottom {
	border: 1px solid #EBEAF3;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	padding: 1px;
}

.order-card-top {
	border: 1px solid #EBEAF3;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding: 1px;
}

.image-fit {
	width: 100%;
	/* or any custom size */
	height: 100%;
	object-fit: fill;
	border-radius: 4px;
}

.status-text-yellow {
	color: #FFB003;
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
}

.status-text-green {
	color: #29B473;
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
}

.status-text-purple {
	color: #5F51A1;
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
}

.btn-primary:hover {
	color: #fff;
	background-color: #5F51A1;
	border-color: #5F51A1;
}</style>

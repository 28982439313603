/* eslint-disable */
export default {
  data() {
    return {
      heraUrl: process.env.VUE_APP_HERA_LINK,
    };
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage
    },
  },
  mounted() {},
  methods: {
    formatRupiah(number) {
      if (number !== undefined) {
      const stringNumber = number.toString();
      const numberString = stringNumber.replace(/[^,\d]/g, '').toString();
      const split = numberString.split(',');
      const remain = split[0].length % 3;
      let rupiah = split[0].substr(0, remain);
      const ribuan = split[0].substr(remain).match(/\d{3}/gi);

      // adding dot if value input has been thousand
      if (ribuan) {
        const separator = remain ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      
      rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah;
      rupiah = `Rp. ${rupiah}`;
      return rupiah;
    } else {
      return "Rp. 0";
    }
    },
    epochToDateTime(epoch) {
      const date = new Date(epoch);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    generateID() {
      const date = new Date();
      const day = (`0${date.getDate()}`).slice(-2);
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const year = date.getFullYear().toString().substr(-2);
      const random = Math.random().toString(36).substring(2, 6).toUpperCase();
      return `INV-DOL-${day}${month}${year}-${random}`;
    },
    swal_alert(message) {
      this.$swal({
        title:
          "<label style='color:white;font-size:12px;text-align:center;z-index:99999999;word-break: break-all; align-items: center;vertical-align: middle;margin-bottom:0px!important'> " +
          message +
          "</label>",
        toast: true,
        showClass: {
          popup: "animate__animated animate__slideInUp",
        },
        hideClass: {
          popup: "animate__animated animate__slideOutDown",
        },
        width: 300,
        padding: "5px",
        background: "rgba(000, 000, 000, 0.5)",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    async copy_to_clipboard(text) {
      console.log(text)
      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      this.swal_alert("Berhasil disalin");
      document.body.removeChild(dummy);
    },
    imageUrlNoContent(event) {
      event.target.src = require("@/assets/images/img-nocontent.svg");
    },
    epochToDateTimeLocalFormat(epoch){
      let locale = "id-ID"
      if (this.language == 'en') {
        locale = "en-US"
      }
      const date = new Date(epoch);
      const year = date.getFullYear();
      const month = date.toLocaleDateString(locale, { month: 'long' });
      const dayname = date.toLocaleDateString(locale, { weekday: 'long' });
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${dayname}, ${day} ${month} ${year} | ${hours}:${minutes}`;
    }

  },
};

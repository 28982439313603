<!-- eslint-disable -->
<template>
  <b-container class="text-12 text-gotham-medium">
    <h2 class="text-purple text-gotham-bold text-24 pb-4">Overdue Dropoff</h2>
    
    <!-- alert failed payment -->
    <div class="card text-red text-12 text-gotham-bold p-3 alert alert-danger" :class="this.isFailed ? '':'d-none'"  style="min-height: 50px;">
      Pembayaran gagal. Silakan periksa saldomu dan coba kembali.
    </div>

    <!-- alert timesout -->
    <div class="card text-yellow text-12 text-gotham-bold p-3 alert alert-warning" :class="this.isTimesOut ? '':'d-none'"  style="min-height: 50px;">
      Waktu pembayaran habis. Silakan coba lagi.
    </div>

    <br />
    <div class="card text-center">
      <div class="card-header bg-white">
        <div class="row py-3">
          <div class="col text-gotham-med text-left">
            Nomor Invoice
          </div>
          <div class="col text-gotham-bold text-right">
            {{this.overdueDropOff !== null ?  this.form.invoice_number: 'INV-DOL-XXXXXX-XXXX'}}
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col text-gotham-med text-left">
            <div class="mb-2 mt-2">Nomor Order</div>
            <div class="mb-2">Pengirim</div>
            <div class="mb-2">Waktu Dibuat</div>
            <div class="mb-2">Lokasi</div>
            <div class="mb-2">Nomor Pintu</div>
          </div>
          <div class="col text-gotham-bold text-right">
            <div class="mb-2 mt-2">{{ this.overdueDropOff !== null ? this.overdueDropOff.expressNumber : '-'}}</div>
            <div class="mb-2">{{ this.overdueDropOff !== null ? this.overdueDropOff.senderName : '-'}}</div>
            <div class="mb-2">{{ this.overdueDropOff !== null ? epochToDateTime(this.overdueDropOff.createTime) : '-'}}</div>
            <div class="mb-2">{{ this.overdueDropOff !== null ? this.overdueDropOff.mouth.box.name : '-'}}</div>
            <div class="mb-2">{{ this.overdueDropOff !== null ? this.overdueDropOff.mouth.number : '-'}}</div>
          </div>
        </div>
      </div>
      <div class="card-footer bg-white">
        <div class="row">
          <div class="col">
            <div class="mb-2 text-gotham-med text-left" @click="showDetail()">
              Total Pembayaran
            </div>
            <!-- detail -->
            <div class="text-10" :class="isDetail ? '' : 'd-none'">
              <div class="mb-2 mt-2 text-gotham-book text-left">
                Biaya overdue per hari
              </div>
              <div class="mb-2 text-gotham-book text-left">
                Jumlah hari
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mb-2 text-gotham-bold text-right text-pointer" @click="showDetail()">
              {{this.overdueDropOff !== null ? formatRupiah(this.overdueDropOff.overdueTotalPrice) : 'Rp. 0'}}
              <img v-if="isDetail" src="@/assets/images/ic-arrow-up.png" style="width:20px">
              <img v-else src="@/assets/images/ic-arrow-down.png" style="width:20px">
            </div>
            <!-- detail -->
            <div class="text-10" :class="isDetail ? '' : 'd-none'">
              <div class="mb-2 mt-2 text-gotham-book text-right">
                {{ this.overdueDropOff !== null ? formatRupiah(this.overdueDropOff.overdueBasePrice) : 'Rp. 0'}}
              </div>
              <div class="mb-2 text-gotham-book text-right">
                {{this.overdueDropOff !== null ? this.overdueDropOff.overdueDays : '-'}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card text-center text-black text-12 p-3 alert alert-warning" :class="isDetail ? '' : 'd-none'" style="min-height: 50px;">
              Harga final akan ditampilkan saat memilih pembayaran.
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card text-left">
      <div class="m-3">
        <b-form-group class="text-gotham-med text-left">
          <label>Nama Penerima <span class="text-red">*</span></label>
          <b-form-input v-model.trim="form.receiver_name" type="text" placeholder="masukan nama penerima"
            class="p-3 text-gotham-book text-12" readonly></b-form-input>
          <b-form-invalid-feedback>
            {{ isValid.receiver_name ? '' : 'Nama penerima tidak boleh kosong' }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="text-gotham-med text-left">
          <label>Nomor Telepon <span class="text-red">*</span></label>
          <b-form-input v-model.trim="form.receiver_contact" type="text" placeholder="masukan nomor telepon"
            class="p-3 text-gotham-book text-12" @input="enforcePhoneNumber" readonly></b-form-input>
          <b-form-invalid-feedback>
            {{ isValid.receiver_contact ? '' : 'Nomor telepon tidak boleh kosong' }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="text-gotham-med text-left">
          <label>Alamat Email <span class="text-red">*</span></label>
          <b-form-input v-model.trim="form.email_address" type="email" placeholder="nama@email.com"
            class="p-3 text-gotham-book text-12"></b-form-input>
          <b-form-invalid-feedback>
            {{ emailIsEmpty ? '' : 'Email tidak valid' }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <br />
    <div>
      <div @click="validSubmit ? onSubmit() : null"
        class="btn-block btn-lg text-gotham-med text-center text-white text-14"
        :class="validSubmit ? 'bg-purple-paxel btn-payment-enable text-pointer' : 'bg-gray btn-payment-disable'">
        Pilih Metode Pembayaran
      </div>
    </div>
    <div class="bg-form h-bg-form-mobile">
    </div>
    <br />
  </b-container>
</template>
<script>
import i18n from '@/plugins/i18n';

export default {
  data() {
    return {
      form: {
        receiver_name: '',
        receiver_contact: '',
        email_address: '',
        invoice_number: '',
      },
      isValid: {
        receiver_name: true,
        receiver_contact: true,
        email_address: true,
      },
      detail: false,
      loading: false,
      minPhoneLength: 8,
      maxPhoneLength: 13,
      isFailed: false,
      isTimesOut: false,
      paxelboxBaseUrl: process.env.VUE_APP_PAXELBOX_BASE_URL,
    };
  },
  beforeMount() {
    // checking query paramater overduepayment
    const paramOrderId = this.$route.query.orderid;
    if (paramOrderId !== undefined) {
      this.$store.dispatch('getOverdueDropoffDetail', paramOrderId)
        .then((response) => {
          if (response.id) {
            const { overdueStatus, status } = response;
            if (status === 'IN_STORE') {
              // status: in store
              switch (overdueStatus) {
                case 'none':
                  this.redirectOverdue('success-payment');
                  break;
                case 'expired':
                  this.redirectOverdue('expire');
                  break;
                default:
                  // this form overdue
                  break;
              }
            } else if (status === 'CUSTOMER_TAKEN') {
              // status: customer taken
              switch (overdueStatus) {
                default:
                  this.redirectOverdue('success');
                  break;
              }
            } else {
              // status: not in store
              switch (overdueStatus) {
                case 'none':
                  this.redirectOverdue('success');
                  break;
                case 'expired':
                  this.redirectOverdue('expire');
                  break;
                default:
                  this.redirectOverdue('success');
                  break;
              }
            }
            this.form.receiver_name = this.overdueDropOff.recipientName;
            this.form.receiver_contact = this.overdueDropOff.takeUserPhoneNumber;
            this.form.invoice_number = this.generateID();

            // handle query param orderid and status
            const paramStatus = this.$route.query.status;
            if (paramOrderId !== undefined && paramOrderId.length !== 0) {
              if (paramStatus === 'cancel') {
                this.isTimesOut = true;
              } else if (paramStatus === 'failed') {
                this.isFailed = true;
              }
            }
          }
        })
        .catch(() => {
          // this.redirectToPaxelbox();
        });
    } else {
      this.redirectToPaxelbox();
    }
  },
  // mounted() {
  //   if (this.overdueDropOff !== null) {
  //     this.form.receiver_name = this.overdueDropOff.recipientName;
  //     this.form.receiver_contact = this.overdueDropOff.takeUserPhoneNumber;
  //     this.form.invoice_number = this.generateID();

  //     // handle query param orderid and status
  //     const paramOrderId = this.$route.query.orderid;
  //     const paramStatus = this.$route.query.status;
  //     if (paramOrderId !== undefined && paramOrderId.length !== 0) {
  //       if (paramStatus === 'cancel') {
  //         this.isTimesOut = true;
  //       } else if (paramStatus === 'failed') {
  //         this.isFailed = true;
  //       }
  //     }
  //   } else {
  //     this.redirectToPaxelbox();
  //   }
  // },
  computed: {
    receiveNameIsEmpty() {
      return !(this.form.receiver_name.length > 0);
    },
    receiveContactIsEmpty() {
      return !(this.form.receiver_contact.length > 0);
    },
    emailIsEmpty() {
      const email = this.form.email_address.trim();
      if (!email || email.length === 0) {
        return false;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const result = emailRegex.test(email);
      return result;
    },
    validSubmit() {
      return (!this.receiveNameIsEmpty && !this.receiveContactIsEmpty && this.emailIsEmpty);
    },
    isDetail() {
      return this.detail;
    },
    overdueDropOff() {
      return this.$store.state.overdueDropOffDetail;
    },
  },
  methods: {
    enforcePhoneNumber(value) {
      this.$nextTick(() => {
        this.form.receiver_contact = value.replace(/\D/g, '');
      });
    },
    onSubmit() {
      if (this.overdueDropOff !== null) {
        const data = {
          orderId: this.overdueDropOff.id,
          formdata: {
            email: this.form.email_address,
            invoice_number: this.form.invoice_number,
            overdue_time: this.overdueDropOff.overdueTime,
            overdue_days: this.overdueDropOff.overdueDays,
            overdue_total_price: this.overdueDropOff.overdueTotalPrice,
          },
        };
        this.$store.dispatch('sendOverdueDropoff', data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.code === 200) {
              // call api generate token payment
              this.$store.dispatch('getTokenPayment').then((responseToken) => {
                const accessToken = responseToken.data.access_token;
                const dataCreatePayment = {
                  token: accessToken,
                  formdata: {
                    client: 'overdue_dropoff_locker',
                    email: this.form.email_address,
                    invoice_number: this.form.invoice_number,
                    cashier_name: this.form.receiver_name,
                    phone_number: this.form.receiver_contact,
                    success_url: `${this.paxelboxBaseUrl}/overduepayment/success-payment?orderid=${this.overdueDropOff.id}`,
                    failed_url: `${this.paxelboxBaseUrl}/overduepayment?orderid=${this.overdueDropOff.id}&status=failed`,
                    cancel_url: `${this.paxelboxBaseUrl}/overduepayment?orderid=${this.overdueDropOff.id}&status=cancel`,
                    locker_order_type: 'Drop Off',
                    order_info: [{
                      order_id: this.overdueDropOff.expressNumber,
                      name: this.form.receiver_name,
                      box_id: this.overdueDropOff.mouth.box.id,
                      door_number: this.overdueDropOff.mouth.number,
                      box_name: this.overdueDropOff.mouth.box.name,
                      size: this.overdueDropOff.mouth.mouthType.name,
                      localization: 'indonesia',
                      duration: this.overdueDropOff.overdueDays,
                      price: this.overdueDropOff.overdueTotalPrice,
                      currency: 'Rp',
                      payment_type: 'extention',
                    }],
                  },
                };
                // call api create payment
                this.$store.dispatch('createPayment', dataCreatePayment).then((responseCreate) => {
                  this.redirectToPayment(responseCreate.payment_url);
                });
              });
            }
          })
          .catch((error) => {
            const message = error.response.data.message || i18n.t('something_wrong');
            this.$swal({
              icon: 'error',
              title: 'Oops...',
              text: message,
            });
          });
      }
    },
    showDetail() {
      this.detail = !this.detail;
    },
    redirectToPaxelbox() {
      this.$router.push({ path: '/' });
    },
    redirectToPayment(url) {
      window.location.href = url;
    },
    redirectOverdue(page) {
      let urlPage = '/';
      if (page === 'success') {
        urlPage = '/overduepayment/success';
      } else if (page === 'expire') {
        urlPage = '/overduepayment/expire';
      } else if (page === 'success-payment') {
        urlPage = '/overduepayment/success-payment';
      }
      this.$router
        .push({ path: urlPage })
        .then(() => { this.$router.go(); });
    },
  },
};
</script>

<template>
  <section
    id="contact"
    class="contact"
    :style="[
      { backgroundImage: 'url(' + require('@/assets/images/desktop-contact.png') + ')' },
      { backgroundSize: 'cover' },
      { backgroundRepeat: 'no-repeat'},
      { backgroundPosition: 'center'},
    ]"
  >
    <div class="container">
      <div class="row">
        <div class="contact-section col-md-6">
          <div class="contact-caption">
            {{ $t("contact_caption") }}
          </div>
          <div class="contact-feature-wrapper row" v-vpshow-scroll="'slide-left'">
            <div class="contact-feature col-md-4">
              <img src="@/assets/icons/bell.svg" alt="Paxel" />
              <div class="contact-feature-text" v-html="$t('contact_value_1')"></div>
            </div>
            <div class="contact-feature col-md-4">
              <img src="@/assets/icons/clock.svg" alt="Paxel" />
              <div class="contact-feature-text" v-html="$t('contact_value_2')"></div>
            </div>
            <div class="contact-feature col-md-4">
              <img src="@/assets/icons/package.svg" alt="Paxel" />
              <div class="contact-feature-text" v-html="$t('contact_value_3')"></div>
            </div>
            <div class="contact-feature col-md-4">
              <img src="@/assets/icons/shield.svg" alt="Paxel" />
              <div class="contact-feature-text" v-html="$t('contact_value_4')"></div>
            </div>
            <div class="contact-feature col-md-4">
              <img src="@/assets/icons/hand.svg" alt="Paxel" />
              <div class="contact-feature-text" v-html="$t('contact_value_5')"></div>
            </div>
            <div class="contact-feature col-md-4">
              <img src="@/assets/icons/touch.svg" alt="Paxel" />
              <div class="contact-feature-text" v-html="$t('contact_value_6')"></div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-vpshow-scroll="'slide-right'">
          <div class="contact-form">
            <div class="contact-header">
              {{ $t("contact_form_caption") }}
            </div>
            <div class="contact-text">
              {{ $t("contact_form_description") }}
            </div>
            <div class="contact-select">
              <VueSelect
                :placeholder="$t('choose_business_type')"
                :clearable="false"
                :searchable="false"
                label="label"
                @input="setSelectedBusinessType"
                :options="[
                  { label: $t('individu'), code: 'Individu' },
                  { label: $t('business'), code: 'Business' }
                ]"
              >
              </VueSelect>
            </div>
            <div class="contact-button">
              <router-link
                tag="a"
                :to="{ name: 'guest-book-form', query: { business_type: selectedBusinesType }}"
                class="bg-purple fill-form-btn"
                :class="{ disabled: selectedBusinesType == '' }" exact>
                {{ $t("fill_form") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'DesktopContact',
  components: { VueSelect },
  methods: {
    setSelectedBusinessType(value) {
      this.selectedBusinesType = value.code;
    },
  },
  data() {
    return {
      selectedBusinesType: '',
    };
  },
};
</script>
<style>
.desktop-home .vs__open-indicator {
  fill: #ffb200;
}
.desktop-home .vs__selected-options {
  height: 35px;
}
.desktop-home .vs__search {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #424143;
}
</style>
<style scoped>
.contact {
  height: 560px;
}
.contact .contact-section {
  margin-top: 60px;
}
.contact .contact-caption {
  font-family: "GothamBold";
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 48px;
  letter-spacing: normal;
  margin-bottom: 32px;
  color: #5e51a1;
  max-width: 280px;
}
.contact .contact-feature-wrapper {
  max-width: 420px;
}
.contact .contact-feature {
  text-align: center;
  margin: 0px 10px 30px 0px;
  padding: 0px 5px 0px 5px;
  max-width: 120px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 27px;
  letter-spacing: normal;
  text-align: center;
  color: #424143;
}
.contact .contact-form {
  max-width: 420px;
  margin-top: 60px;
  float: right;
  border-radius: 8px;
  padding: 40px;
  background-color: rgba(249, 249, 251, 0.8);
  border: 0.5px solid #dedede;
}
.contact .contact-header {
  font-family: "GothamMedium";
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 41px;
  letter-spacing: normal;
  color: #5e51a1;
}
.contact .contact-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 27px;
  letter-spacing: normal;
  color: #424143;
  border-bottom: solid 0.5px #d8d8d8;
}
.contact .contact-select {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #ffffff;
}
.contact-button a {
  font-family: "GothamBold";
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: normal;
  border-radius: 0.25rem;
  text-align: center;
  display: block;
  width: 100%;
  color: #ffffff;
}
.contact-button a.fill-form-btn:hover {
  background-color: #524494;
}
.contact-button a.fill-form-btn:focus {
  border: none;
}
.contact-button a .arrow-right {
  float: right;
  padding-right: 20px;
}
</style>

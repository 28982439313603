import axios from 'axios';

let paymentAPI = null;
const PaymentAPIService = {
  init(url) {
    paymentAPI = axios.create({
      baseURL: url,
      timeout: 60000,
    });
  },
  post(resource, data) {
    return paymentAPI.post(resource, data);
  },
};

export default PaymentAPIService;

// getters
const getters = {
  getLanguage: (state) => state.language,
  getPaxelBoxBanners: (state) => state.paxelBoxBanners,
  getPaxelBoxServices: (state) => state.paxelBoxServices,
  getGuestBook: (state) => state.guestBook,
  getAvailableLockers: (state) => state.availableLockers,
  getPackageLocker: (state) => state.packageLocker,
  getShortener: (state) => state.shortener,
  getOverdueDropoffDetail: (state) => state.overdueDropOffDetail,
  getOverdueDropoff: (state) => state.overdueDropOff,
};

export default getters;

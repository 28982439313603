<template>
  <section id="shortener" class="shortener">
    <div class="container">
      <PaxelLoader :loading="isLoading"></PaxelLoader>
      <div v-if="!isLoading" class="text-center">
        <img class="img-fluid" src="@/assets/images/not-found.svg" >
        <div class="row justify-content-md-center">
          <div class="col-md-4 mt-3">
            <div class="shortener-caption">Ooops..</div>
            <div class="shortener-description">
              {{ $t('your_request_could_not_be_found') }}
            </div>
            <div class="back-button" col-md-3>
              <router-link
                tag="a"
                :to="{ name: 'index', hash: '#paxelbox' }"
                class="bg-purple back-home-btn">
                {{ $t("back_to_paxelbox") }}
                <span class="arrow-right"><i class="fas fa-arrow-right"></i></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PaxelLoader from '@/components/PaxelLoader.vue';

export default {
  name: 'ShortenerRedirect',
  components: {
    PaxelLoader,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    const code = this.$route.params.code || '';
    if (code !== '') {
      this.$store.dispatch('getShortener', code);
    } else {
      this.isLoading = false;
    }
  },
  created() {
    this.$store.watch(
      (state) => state.shortener,
      (newValue) => {
        let url = newValue.url || '';
        if (url !== '') {
          const pattern = /^((http|https):\/\/)/;
          if (!pattern.test(url)) {
            url = `http://${url}`;
          }
          window.location.href = url;
        } else {
          this.isLoading = false;
        }
      },
    );
  },
};
</script>

<style scoped>
.shortener {
  padding: 60px 0px 170px 0px;
}
.shortener .shortener-caption {
  font-family: "GothamMedium";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #424143;
  margin-top: 12px;
  margin-bottom: 12px;
}
.shortener .shortener-description {
  font-family: "GothamBook";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #929293;
}
.back-button {
  margin-top: 60px;
}
.back-button a {
  font-family: "GothamBold";
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: normal;
  border-radius: 0.25rem;
  text-align: center;
  display: block;
  width: 100%;
  color: #ffffff;
  text-transform: uppercase;
}
.back-button a.back-home-btn:hover {
  background-color: #524494;
}
.back-button a.back-home-btn:focus {
  border: none;
}
.back-button a .arrow-right {
  float: right;
  padding-right: 20px;
}
@media (max-width: 992px) {
  .shortener {
    padding: 25px 0px 170px 0px;
  }
}
</style>

<template>
  <section id="paxelbox" class="paxelbox">
    <VueAgile :options="slideOptions" v-if="!loadingBanner" v-vpshow-scroll>
      <div class="agile-carousel" v-for="(banner, index) in banners" :key="index">
        <div class="agile-carousel-caption">
          <div class="container">
            <h3 class="caption">{{ banner.header }}</h3>
            <div class="text" v-html="banner.sub_heading"></div>
            <div class="cta-button mt-2"
              v-if="banner.button.article != null ||
                    banner.button.other_url != ''">
              <a :href="ctaURL(banner.button)" class="cta-link-btn">
                {{ banner.button.text_button }}
              </a>
            </div>
            <div class="download-button mt-2">
              <a target="_blank"
                :href="banner.download_button.play_store_url"
                v-if="banner.is_with_download_button
                  && banner.download_button.play_store_icon != ''">
                <img
                  :src="banner.download_button.play_store_icon"
                  alt="Paxel App"
                  class="img-fluid mt-2"
                  width="134"
                >
              </a>
              <a target="_blank"
                :href="banner.download_button.app_store_url"
                v-if="banner.is_with_download_button
                  && banner.download_button.app_store_icon != ''">
                <img
                  :src="banner.download_button.app_store_icon"
                  alt="Paxel App"
                  class="img-fluid mt-2"
                  width="134"
                >
              </a>
            </div>
          </div>
        </div>
        <div class="bg-purple upper-background"></div>
        <img :src="banner.image_mobile_jpg" :alt="banner.header" class="agile-carousel-image" />
      </div>
      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
    </VueAgile>
    <PaxelLoader :loading="loadingBanner"></PaxelLoader>
  </section>
</template>

<script>
import { VueAgile } from 'vue-agile';
import i18n from '@/plugins/i18n';
import PaxelLoader from '@/components/PaxelLoader.vue';

export default {
  name: 'MobilePaxelBox',
  components: {
    VueAgile,
    PaxelLoader,
  },
  data() {
    return {
      banners: [],
      loadingBanner: true,
      slideOptions: {
        navButtons: false,
        dots: false,
        fade: true,
        autoplay: false,
      },
    };
  },
  methods: {
    ctaURL(button) {
      const url = button.is_article
        ? `${this.$root.revampWebBaseURL}/${i18n.locale}/news-and-promos/detail/${button.article.created_at_date_only}/${button.article.slug}`
        : button.other_url;
      return url;
    },
  },
  mounted() {
    this.$store.dispatch('fetchPaxelBoxBanners', {
      language: this.$store.getters.getLanguage,
      limit: 1,
    });
  },
  created() {
    this.$store.watch(
      (state) => state.paxelBoxBanners,
      (newValue) => {
        this.loadingBanner = true;
        this.banners = newValue;
        this.loadingBanner = false;
      },
    );
    this.$store.watch(
      (state) => state.language,
      (newValue) => {
        this.$store.dispatch('fetchPaxelBoxBanners', { language: newValue, limit: 1 });
      },
    );
  },
};
</script>

<style>
.mobile-home .agile__nav-button {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 80px;
}
.mobile-home .agile__nav-button:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: #ffffff;
  opacity: 1;
}
.mobile-home .agile__nav-button--prev {
  left: 0;
}
.mobile-home .agile__nav-button--next {
  right: 0;
}
.mobile-home .agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.mobile-home .agile__dot {
  margin: 0 10px;
}
.mobile-home .agile__dot button {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}
.mobile-home .agile__dot--current button,
.mobile-home .agile__dot:hover button {
  background-color: #ffffff;
}
.mobile-home .agile-carousel-image {
  display: block;
  height: auto;
  -o-object-fit: cover;
  object-fit: contain;
  width: 100%;
}
.mobile-home .agile-carousel-caption {
  position: absolute;
  right: auto;
  top: 1%;
  width: 100%;
  left: auto;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  text-align: left;
  color: #ffffff;
}
.mobile-home .agile-carousel-caption .caption {
  font-family: "GothamBold";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0.21px;
  color: #ffffff;
  margin-bottom: 20px;
  width: 165px;
}
.mobile-home .agile-carousel-caption .text {
  font-family: "GothamMedium";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #ffffff;
}
</style>
<style scoped>
.mobile-home .upper-background {
  height: 250px;
  margin-bottom: -1px;
  border-bottom: 5px solid #5e51a1;
}
.mobile-home .cta-button {
  display: inline-flex;
}
.mobile-home .cta-button a {
  font-family: "GothamBold";
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border: none;
  padding: 12px;
  letter-spacing: normal;
  border-radius: 0.25rem;
  text-align: center;
  color: #424143;
  background-color: #ffffff;
  width: 134px;
}
.mobile-home .cta-button a.cta-link-btn:focus {
  border: none;
}
.mobile-home .cta-button a.cta-link-btn:hover {
  opacity: 0.9;
}
.mobile-home .download-button {
  display: flex;
  flex-direction: row;
}
/* .mobile-home a {
  flex: 0.25 1 auto;
} */
.mobile-home .download-button img {
  border-radius: 8px;
  margin-right: 5px;
}
</style>

<template>
  <section id="location">
    <div class="container">
      <div class="location-captions">
        {{ $t('location_caption') }}
      </div>
      <div class="location-descriptions">
        {{ $t('location_description') }}<b>{{ $t('and_still_growing') }}</b>
      </div>
    </div>
    <div class="location-map">
      <iframe
          :src="embeddedMapUrl"
          height="480"
          width="100%"
          frameborder="0"
          v-vpshow-scroll
        ></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MobileLocation',
  data() {
    return {
      embeddedMapUrl: process.env.VUE_APP_EMBEDDED_MAP,
    };
  },
};
</script>

<style scoped>
  .location-captions {
    font-family: "GothamBold";
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 0.24px;
    color: #5e50a1;
    margin-bottom: 10px;
  }
  .location-descriptions {
    font-family: "GothamBook";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    color: #424143;
    margin-bottom: 43px;
  }
</style>

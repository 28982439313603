<template>
  <section id="package" class="package">
    <div class="package-caption">
      <div class="container">
        {{ $t('data_package') }}
      </div>
    </div>
    <div class="package-form">
      <div class="container">
        <b-row>
          <b-col sm="6">
            <b-overlay :show="loading" rounded="sm" bg-color="#f8f9fa">
              <b-form @submit.stop.prevent="onSubmit">
                <b-form-group
                  id="sender-name-group"
                  :label="`${$t('sender_name')}/${$t('delivery_provider')}`"
                  label-for="sender-name"
                >
                  <b-form-input
                    id="sender-name"
                    v-model.trim="form.sender_name"
                    type="text"
                    aria-describedby="input-sender-name-help input-sender-name-feedback"
                    :placeholder="$t('enter_sender_name')"
                    :class="{ 'is-invalid': $v.form.sender_name.$error }"
                  ></b-form-input>
                  <b-form-text id="input-sender-name-help">
                    {{ $t('delivery_provider') + ": GoSend, JNE, J&T, " + $t('etc') }}
                  </b-form-text>
                  <b-form-invalid-feedback
                    id="input-sender-name-feedback">
                    {{ $t('sender_name') + "/ " + $t('delivery_provider') + " "
                    + $t('should_not_empty') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="recipient-name-group"
                  :label="$t('recipient_name')"
                  label-for="recipient-name"
                >
                  <b-form-input
                    id="recipient-name"
                    v-model.trim="form.recipient_name"
                    type="text"
                    aria-describedby="input-recipient-name-feedback"
                    :placeholder="$t('enter_recipient_name')"
                    :class="{ 'is-invalid': $v.form.recipient_name.$error }"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-recipient-name-feedback">
                    {{ $t('recipient_name') + " " + $t('should_not_empty') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="recipient-mobile-number-group"
                  :label="$t('recipient_mobile_number')"
                  label-for="recipient-mobile-number"
                >
                  <b-form-input
                    id="recipient-mobile-number"
                    v-model.trim="form.recipient_mobile_number"
                    type="text"
                    aria-describedby="input-recipient-mobile-number-feedback"
                    :placeholder="$t('enter_recipient_mobile_number')"
                    :class="{ 'is-invalid': $v.form.recipient_mobile_number.$error }"
                    @input="enforcePhoneNumber"
                  ></b-form-input>
                  <b-form-text id="input-sender-name-help">
                    {{ $t('ex') + ": 0811222233333"}}
                  </b-form-text>
                  <b-form-invalid-feedback
                    id="input-recipient-mobile-number-feedback">
                    {{ $t('recipient_mobile_number') + " " + $t('should_not_empty') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="locker-location-group"
                  :label="$t('locker_location')"
                  label-for="locker-location"
                >
                  <b-form-input
                    id="locker-location"
                    v-model.trim="form.locker_location"
                    type="text"
                    aria-describedby="input-locker-location-feedback"
                    :class="{ 'is-invalid': $v.form.locker_location.$error }"
                    readonly
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-locker-location-feedback">
                    {{ $t('locker_location') + " " + $t('should_not_empty') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="package-size-group"
                  :label="$t('package_size')"
                  label-for="package-size"
                >
                  <VueSelect
                    :class="{ 'is-invalid': $v.form.package_size.$error }"
                    :clearable="false"
                    :searchable="false"
                    :selected="form.package_size"
                    :placeholder="$t('choose_package_size')"
                    @input="setPackageSize"
                    :options="availableLockers"
                  >
                    <template #selected-option="{ name, dimention }">
                      <span>{{ name }} [{{ dimention }} cm] (T x P x L)</span>
                    </template>
                    <template #option="{ name, dimention }">
                      <span>{{ name }} [{{ dimention }} cm] (T x P x L)</span>
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{ $t('no_locker_available') }} <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>{{ $t('no_locker_available') }}</em>
                    </template>
                  </VueSelect>
                  <b-form-invalid-feedback
                    id="input-package-size-feedback">
                    {{ $t('package_size') + " " + $t('should_not_empty') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="package-photo-group"
                  :label="$t('package_photo')"
                  label-for="package-photo">
                  <b-card
                    body-class="p-1"
                    :class="{ 'is-invalid': $v.form.package_photo.$error }">
                    <b-row style="margin-left: -10px; margin-right: -10px">
                      <b-col class="align-self-center">
                          <i class="fas fa-image"></i>
                          <span class="pl-2">
                            {{form.package_photo.name || $t('select_photo') }}
                          </span>
                      </b-col>
                      <b-col align="right">
                        <div class="d-flex">
                          <input
                              id="package-photo"
                              type="file"
                              accept="image/*"
                              ref="package_photo"
                              name="package-photo"
                              aria-describedby="input-package-photo-feedback"
                              style="display: none"
                              @input="onSelectPhoto">
                          <div class="w-100" v-show="!form.package_photo">
                            <b-button
                              class="photo-btn"
                              @click="choosePhoto"
                              variant="outline-light">
                              {{ $t('select_photo') }}</b-button>
                          </div>
                          <div class="w-100" v-show="form.package_photo">
                            <b-button
                            class="photo-btn"
                            @click="photoModal = !photoModal"
                            variant="outline-light">
                            {{ $t('preview_photo') }}</b-button>
                          </div>
                          <div class="w-100" v-show="form.package_photo">
                            <b-button
                            class="change-photo-btn ml-2"
                            @click="choosePhoto"
                            variant="outline-light">
                          {{ $t('change_photo') }}</b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-form-invalid-feedback
                    id="input-package-photo-feedback">
                    {{ $t('package_photo') + " " + $t('should_not_empty') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="slider-verification-group"
                  :label="$t('slider_verification')"
                  label-for="slider-verification"
                  style="{ user-select: none; }"
                >
                  <drag-verify
                    id="slider-verification"
                    ref="slider_verification"
                    :width="250"
                    :text="$t('swipe_to_the_righ_side')"
                    :successText="$t('verification_success')"
                    :isPassing.sync="form.slider_verification"
                    handlerIcon="fas fa-angle-double-right"
                    successIcon="fas fa-check-circle"
                    aria-describedby="input-slider-verification-feedback"
                    :class="{ 'is-invalid': $v.form.slider_verification.$error }"
                  >
                  </drag-verify>
                  <b-form-invalid-feedback
                    id="input-slider-verification-feedback">
                    {{ $t('please_swipe_for_verification') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="mt-4">
                  <b-button
                    id="confirm-btn"
                    type="submit"
                    variant="outline-light"
                    class="btn-block btn-lg confirm-btn">
                    {{ $t('confirm') }}
                  </b-button>
                </b-form-group>
              </b-form>
            </b-overlay>
          </b-col>
          <b-col sm="6" id="package-qrcode-wrapper">
              <b-form-group
                id="package-qrcode"
                label="QR Code"
                label-for="package-qrcode"
                v-if="showQRCode.generating || showQRCode.generated">
                <b-card>
                  <div v-if="showQRCode.generated" class="d-flex justify-content-center">
                    <img v-if="qrcodeNotEmpty" :src="`data:image/jpg;base64,${showQRCode.qrcode}`"
                      class="img-fluid" alt="qrcode" />
                  </div>
                  <PaxelLoader :loading="showQRCode.generating"></PaxelLoader>
                </b-card>
              </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal v-model="photoModal" title="Preview Photo" centered cancel-disabled size="md">
      <img v-if="form.package_photo_url" class="img-fluid" :src="form.package_photo_url" />
      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="primary" @click="ok()">
          OK
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import i18n from '@/plugins/i18n';
import { required, sameAs } from 'vuelidate/lib/validators';
import CustomValidators, { minPhone, maxPhone } from '@/validators';
import DragVerify from '@/components/DragVerify.vue';
import PaxelLoader from '@/components/PaxelLoader.vue';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'MobilePackage',
  components: { VueSelect, DragVerify, PaxelLoader },
  data() {
    return {
      loading: false,
      minPhoneLength: minPhone,
      maxPhoneLength: maxPhone,
      photoModal: false,
      showQRCode: {
        generating: false,
        generated: false,
        qrcode: '',
      },
      form: {
        boxId: '',
        sender_name: '',
        recipient_name: '',
        recipient_mobile_number: '',
        locker_location: '',
        package_size: '',
        package_photo: '',
        package_photo_url: '',
        slider_verification: false,
      },
    };
  },
  validations: {
    form: {
      sender_name: { required },
      recipient_name: { required },
      recipient_mobile_number: { required, phone: CustomValidators.phoneValidate },
      locker_location: { required },
      package_size: { required },
      package_photo: { required },
      slider_verification: { sameAs: sameAs(() => true) },
    },
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch();
      this.$root.scrollToTop();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$nextTick(() => {
        this.loading = true;
        this.qrCodeGenerating();
      });

      // build data
      const data = {
        takeUserPhoneNumber: this.form.recipient_mobile_number,
        box: {
          id: this.form.boxId,
        },
        mouth: {
          mouthType: {
            name: this.form.package_size.name,
          },
        },
        photo: this.form.package_photo_url,
        recipientName: this.form.recipient_name,
        takeUserLanguage: 'Indonesia',
        senderName: this.form.sender_name,
      };

      this.$store.dispatch('sendPackageData', data)
        .then(() => {
          this.$root.scrollToAnchor('package-qrcode-wrapper');
          setTimeout(() => {
            this.qrCodeGenerated();
            this.loading = false;
          },
          2000);
        })
        .catch((error) => {
          setTimeout(() => {
            this.qrCodeNotGenerated();
            this.loading = false;
          },
          2000);
          const message = error.response.data.message || i18n.t('something_wrong');
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
        });
    },
    enforcePhoneNumber(value) {
      this.$nextTick(() => {
        this.form.recipient_mobile_number = value.replace(/\D/g, '');
      });
    },
    setPackageSize(value) {
      this.form.package_size = value;
    },
    choosePhoto() {
      this.$refs.package_photo.click();
    },
    onSelectPhoto() {
      const input = this.$refs.package_photo;
      const { files } = input;
      if (files && files[0]) {
        const [contentFile] = files;
        this.form.package_photo = contentFile;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.package_photo_url = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit('input', files[0]);
      }
    },
    qrCodeGenerating() {
      this.showQRCode.generating = true;
      this.showQRCode.generated = false;
    },
    qrCodeGenerated() {
      this.showQRCode.generating = false;
      this.showQRCode.generated = true;
    },
    qrCodeNotGenerated() {
      this.showQRCode.generating = false;
      this.showQRCode.generated = false;
    },
  },
  computed: {
    availableLockers() {
      return this.$store.getters.getAvailableLockers
        .map((item) => Object.assign(item, { label: item.name }));
    },
    qrcodeNotEmpty() {
      return this.showQRCode.qrcode !== '';
    },
  },
  created() {
    this.$root.scrollToTop();
    if (this.$route.query) {
      const { boxId, name } = this.$route.query;
      if (boxId) {
        this.form.boxId = boxId;
        this.$store.dispatch('fetchAvailableLockerSize', boxId);
      }
      if (name) {
        this.form.locker_location = name;
      }
    }
    this.$store.watch(
      (state) => state.packageLocker,
      (newValue) => {
        this.showQRCode.qrcode = newValue.qrcode
          ? btoa(String.fromCharCode(...new Uint8Array(newValue.qrcode)))
          : '';
      },
    );
  },
};
</script>

<style>
.package-form .spinner-border {
  color: #5e50a1;
}
.package-form label {
  font-family: "GothamMedium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.package-form input,
.package-form input::placeholder,
.package-form .v-select {
  font-family: "GothamBook";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.package-form .v-select.is-invalid > .vs__dropdown-toggle {
  border-color: #dc3545;
}
.package-form .v-select {
  background-color: #ffffff;
}
.package-form .vs__dropdown-toggle {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}
.package-form .vs__search,
.package-form .vs__selected {
  margin-top: 0px;
  padding-left: 0px;
}
.package-form .vs__actions {
  padding-top: 0px;
}
.package-form .drag_verify .dv_handler i{
  color: #5e50a1;
}
</style>

<style scoped>
.package-caption {
  font-family: "GothamMedium";
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 41px;
  letter-spacing: normal;
  color: #5e51a1;
  padding: 25px 0px 22px 0px;
}
.package-form {
  padding: 65px 0px;
  background-color: #f9f9fb;
}
.package-form .confirm-btn,
.package-form .photo-btn,
.package-form .change-photo-btn {
  font-family: "GothamMedium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #5e50a1;
}
.package-form .confirm-btn:focus,
.package-form .photo-btn:focus,
.package-form .change-photo-btn:focus {
  outline: none;
  box-shadow: none;
}
.package-form .change-photo-btn {
  color: #5e50a1;
  background-color: #ffffff;
  border: 1px solid #5e50a1;
}
.package-form .card.is-invalid {
  border-color: #dc3545;
}
</style>

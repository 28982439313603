import LocalStorage from '@/services/localstorage';
// state
const state = {
  language: LocalStorage.getLanguage(),
  paxelBoxBanners: [],
  paxelBoxServices: [],
  guestBook: [],
  availableLockers: [],
  packageLocker: {},
  shortener: {},
  overdueDropOffDetail: JSON.parse(LocalStorage.getOverdue()),
  overdueDropOff: {},
};

export default state;

<template>
  <div class="desktop-services">
    <Banner />
    <Content />
    <Location />
  </div>
</template>

<script>
import Banner from './Banner.vue';
import Content from './Content.vue';
import Location from './Location.vue';

export default {
  name: 'DesktopPaxelBoxServices',
  components: {
    Banner,
    Content,
    Location,
  },
  created() {
    setTimeout(() => this.$root.scrollToAnchor(this.$route.hash), 2000);
  },
};
</script>
<style>
.desktop-services section {
  margin-bottom: 40px;
}
.desktop-services section .section-caption {
  font-family: "GothamBold";
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 48px;
  letter-spacing: normal;
  margin-bottom: 40px;
  color: #5e51a1;
}
.desktop-services section.location {
  margin-bottom: 0px;
}
</style>

<template>
  <section id="service-content" class="service-content">
    <div v-for="(service, index) in services" :key="index" :id="service.slug">
      <div class="section-description-container">
        <div class="container">
          <div class="section-caption">{{ service.title }}</div>
          <div class="section-content"
            v-html="$options.filters.convertEmbedYoutube(service.description)">
          </div>
          <div v-if="service.video_url !== ''" class="section-video">
            <div class="w-50">
              <b-embed
                type="iframe"
                aspect="16by9"
                :src="getVideoUrl(service.video_url)"
                allowfullscreen
              ></b-embed>
            </div>
          </div>
        </div>
      </div>
      <div class="section-howto-container">
        <div class="container">
          <div class="section-caption">{{ $t('how_to_use') }}</div>
          <div class="section-content">
            <b-tabs
              v-model="serviceIndexes[index].currentTab"
              pills vertical nav-wrapper-class="tab-container">
              <b-tab v-for="(guide, idx) in service.guides"
              :key="idx">
                <template #title>
                  <div class="tab-title-container">
                    <span class="tab-number">{{ idx + 1 }}</span>
                    <span class="tab-title" v-html="guide.description"></span>
                  </div>
                </template>
                <div class="tab-content-container">
                  <span
                    class="tab-content-nav tab-left-nav"
                    :style="isFirstTab(index) ? 'opacity: 0.3;' : ''"
                    @click="prevTab(index)">
                    <i class="fas fa-chevron-left"></i>
                  </span>
                  <img
                    :src="guide.image"
                    width="200px"
                    height="440px"
                    class="img-fluid"/>
                  <span
                    class="tab-content-nav tab-right-nav"
                    :style="isLastTab(index, service.guides) ? 'opacity: 0.3;' : ''"
                    @click="nextTab(index, service.guides)">
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
      <div
        class="section-download-container"
        v-if="service.download_button.play_store_icon != '' &&
              service.download_button.app_store_icon != ''">
        <div class="container">
          <div class="section-download">
            <div class="download-text">{{ $t('download_paxel_app') }}</div>
            <div class="download-icon">
              <a
                :href="service.download_button.play_store_url"
                v-if="service.download_button.play_store_icon != ''"
              >
                <img :src="service.download_button.play_store_icon" :alt="service.title" />
              </a>
              <a
                :href="service.download_button.app_store_url"
                v-if="service.download_button.app_store_icon != ''"
              >
                <img :src="service.download_button.app_store_icon" :alt="service.title" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PaxelLoader :loading="loadingService"></PaxelLoader>
  </section>
</template>

<script>
import PaxelLoader from '@/components/PaxelLoader.vue';

export default {
  name: 'DesktopServiceContent',
  components: {
    PaxelLoader,
  },
  data() {
    return {
      services: [],
      serviceIndexes: [],
      loadingService: true,
    };
  },
  mounted() {
    this.$store.dispatch('fetchPaxelBoxServices', {
      language: this.$store.getters.getLanguage,
      limit: -1, // all data
    });
  },
  created() {
    this.$store.watch(
      (state) => state.paxelBoxServices,
      (newValue) => {
        this.loadingService = true;
        this.services = newValue;
        this.serviceIndexes = newValue.map(() => ({ currentTab: 0 }));
        this.loadingService = false;
      },
    );
    this.$store.watch(
      (state) => state.language,
      (newValue) => {
        this.$store.dispatch('fetchPaxelBoxServices', { language: newValue, limit: 3 });
      },
    );
    this.$root.scrollToAnchor(this.$route.hash);
  },
  methods: {
    nextTab(index, guides) {
      if (this.serviceIndexes[index].currentTab < guides.length - 1) {
        this.serviceIndexes[index].currentTab += 1;
      }
    },
    prevTab(index) {
      if (this.serviceIndexes[index].currentTab > 0) {
        this.serviceIndexes[index].currentTab -= 1;
      }
    },
    isFirstTab(index) {
      return this.serviceIndexes[index].currentTab === 0;
    },
    isLastTab(index, guides) {
      return this.serviceIndexes[index].currentTab === guides.length - 1;
    },
    getVideoUrl(url) {
      let videourl = url;
      const pattern = /^((http|https):\/\/)/;
      if (!pattern.test(url)) {
        videourl = `http://${url}`;
      }
      return videourl;
    },
  },
};
</script>
<style scoped>
.section-description-container {
  padding: 40px 0px 40px 0px;
}
.section-description-container .section-content {
  font-family: "GothamBook";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #424143;
}
.section-description-container .section-video {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.section-howto-container {
  background-color: #f9f9fb;
  padding: 40px 0px 40px 0px;
}
.section-howto-container .section-content {
  font-family: "GothamBook";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  color: #575758;
}
.section-howto-container .tab-title-container {
  display: flex;
  flex-wrap: nowrap;
}
.section-howto-container .tab-title-container .tab-title > :last-child {
  margin: 0;
}
.section-howto-container .tab-content-container {
  display: flex;
  justify-content: center;
}
.section-howto-container .tab-content-container .tab-content-nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #5e50a1;
}
.section-howto-container .tab-content-container .tab-left-nav {
  padding-right: 150px;
}
.section-howto-container .tab-content-container .tab-right-nav {
  padding-left: 150px;
}
</style>
<style>
.section-howto-container .section-content .tabs .tab-container {
  width: 450px;
}
.section-howto-container .nav-pills .nav-link {
  font-family: "GothamBook";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  color: #575758;
}
.section-howto-container .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  border-radius: 12px;
  color: #ffffff;
  background-color: #5e50a1;
}
.section-howto-container .nav-pills .nav-link .tab-number {
  font-family: "GothamBold";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5e50a1;
  border-radius: 40px;
  background-color: transparent;
  padding: 5px 15px;
  border: 2px solid #5e50a1;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
}
.section-howto-container .nav-pills .nav-link.active .tab-number {
  background-color: #ffffff;
}
.section-download-container {
  padding: 40px 0px 20px 0px;
}
.section-download-container {
  text-align: center;
}
.section-download-container .download-text {
  font-family: "GothamBold";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: center;
  color: #5e50a1;
  padding-bottom: 25px;
}
.section-download-container .download-icon a {
  padding: 0px 15px;
}
</style>

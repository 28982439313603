<template>
  <section id="header" class="mobile-header fixed-top">
    <div class="bg-purple">
      <b-navbar class="main-navbar mobile-navbar container" toggleable="lg" type="dark">
        <b-navbar-brand>
          <a :href="`${this.revampWebURL}`">
            <img src="@/assets/logo.svg" width="92" height="30" alt="Paxel" />
          </a>
        </b-navbar-brand>
        <b-navbar-toggle target="main-navbar-collapse">
          <template #default="{ expanded }">
            <span v-if="!expanded" class="navbar-toggler-icon"></span>
            <span v-else></span>
          </template>
        </b-navbar-toggle>
        <b-sidebar
          id="main-navbar-collapse"
          bg-variant="none"
          width="80%"
          text-variant="light"
          :no-header-close="!isTooglerShow"
          backdrop
          no-close-on-backdrop
          is-nav
          @change="changeToggler">
          <template slot="title">
            <a :href="revampWebURL">
              <img src="@/assets/logo.svg" width="92" height="30" alt="Paxel" />
            </a>
          </template>
          <template slot="header-close">
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              focusable="false"
              role="img"
              aria-label="x"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="bi-x b-icon bi"
            >
              <g>
                <path
                  fill-rule="evenodd"
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1
                  .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646
                  2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                ></path>
              </g>
            </svg>
          </template>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :href="`${revampWebURL}/our-service`">
              {{ $t('our_services') }}
            </b-nav-item>
            <b-nav-item :href="`${revampWebURL}/news-and-promos`">
              {{ $t('news_and_promos') }}
            </b-nav-item>
            <b-nav-item :href="`${revampWebURL}/antarkan-kebaikan`">
              #AntarkanKebaikan
            </b-nav-item>
            <b-nav-item-dropdown menu-class="bg-purple border-none shadow">
                <template slot="text">
                    {{ $t('partnership') }}
                </template>
                <b-dropdown-item :href="`${revampWebURL}/partnership`">
                  {{ $t('about_partnership') }}
                </b-dropdown-item>
                <b-dropdown-item :href="corporateURL">
                  Corporate
                </b-dropdown-item>
                <b-dropdown-item :href="`${revampWebBaseURL}/mitrapaxel`">
                  Mitra PaxelCo
                </b-dropdown-item>
                <b-dropdown-item :href="paxelbigURL">
                  PaxelBig
                </b-dropdown-item>
                <b-dropdown-item to="/#paxelbox">PaxelBox</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              menu-class="bg-purple border-none shadow">
                <template slot="button-content">
                    <flag :iso="selectedFlag" v-bind:squared="false"/>
                    <span style="fontSize: 14px;" v-if="selectedLanguage">
                      {{ selectedLanguage.title }}
                    </span>
                </template>
                <b-dropdown-item
                  v-for="entry in availableLanguages"
                  :key="entry.title"
                  @click="changeLocale(entry.language)">
                    <flag :iso="entry.flag" v-bind:squared="false" />
                    <span style="fontSize:14px;verticalAlign: text-top;">
                      {{ entry.title }}
                    </span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-sidebar>
      </b-navbar>
    </div>
    <div class="bg-dark-purple">
      <b-navbar class="sub-navbar mobile-navbar container" toggleable="lg" type="dark">
        <b-navbar-brand
          :to="{name: 'index', hash: '#paxelbox'}"
          class="mobile-sub-navbar-brand"
          :class="isPaxelBoxActivate"
          @click="scrollToAnchor">
          PaxelBox
        </b-navbar-brand>
        <b-navbar-toggle target="sub-navbar-collapse">
          <template #default="{ expanded }">
            <span v-if="expanded">
              <svg width="1em" height="1em" viewBox="0 0 16 16"
                class="bi bi-chevron-up" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0
                1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
              </svg>
            </span>
            <span v-else>
              <svg width="1em" height="1em" viewBox="0 0 16 16"
                class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1
                .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0
                1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
          </template>
        </b-navbar-toggle>
        <b-collapse id="sub-navbar-collapse" v-b-scrollspy="scrollConfig" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{name: 'index', hash: '#services'}" @click="scrollToAnchor">
              {{ $t('services') }}
            </b-nav-item>
            <b-nav-item :to="{name: 'index', hash: '#location'}" @click="scrollToAnchor">
              {{ $t('location') }}
            </b-nav-item>
            <b-nav-item :to="{name: 'index', hash: '#contact'}" @click="scrollToAnchor">
              {{ $t('contact') }}
            </b-nav-item>
            <b-nav-item :href="`${revampWebURL}/help-and-support`">
              FAQ
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </section>
</template>

<script>
import i18n from '@/plugins/i18n';
import LocalStorage from '@/services/localstorage';

export default {
  name: 'MobileHeader',
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
      this.selectedFlag = locale === 'id' ? 'id' : 'gb';
      this.$store.dispatch('changeLanguage', locale);
      LocalStorage.setLanguage(locale);
    },
    scrollToAnchor() {
      this.$root.scrollToAnchor(this.$route.hash);
    },
    changeToggler(visible) {
      if (visible) {
        setTimeout(() => {
          this.isTooglerShow = true;
        }, 300);
      } else {
        this.isTooglerShow = false;
      }
    },
  },
  computed: {
    selectedLanguage() {
      return this.languages.find((a) => a.language === i18n.locale);
    },
    availableLanguages() {
      return this.languages.slice(0).sort((a) => (a.language === i18n.locale ? -1 : 1));
    },
    revampWebURL() {
      return `${this.revampWebBaseURL}/${i18n.locale}`;
    },
    isPaxelBoxActivate() {
      return this.isPaxelBox ? 'bg-white tx-purple' : 'tx-white';
    },
    revampFAQParams() {
      return `?category=${i18n.locale === 'id' ? this.revampWebFAQParamId : this.revampWebFAQParamEn}`;
    },
  },
  data() {
    return {
      revampWebBaseURL: process.env.VUE_APP_REVAMP_WEB,
      revampWebFAQParamEn: process.env.VUE_APP_FAQ_PARAM_EN,
      revampWebFAQParamId: process.env.VUE_APP_FAQ_PARAM_ID,
      paxelbigURL: process.env.VUE_APP_PAXEL_BIG_WEB,
      corporateURL: process.env.VUE_APP_CORPORATE_WEB,
      selectedFlag: i18n.locale === 'id' ? 'id' : 'gb',
      scrollConfig: {
        element: 'body',
        offset: 270,
        method: 'auto',
        throttle: 3000,
      },
      languages: [
        { flag: 'gb', language: 'en', title: 'English' },
        { flag: 'id', language: 'id', title: 'Bahasa' },
      ],
      isTooglerShow: false,
      isPaxelBox: true,
    };
  },
};
</script>

<style scoped>
  #header.fixed-top {
    background-color: #5e50a1;
  }
  .mobile-navbar.main-navbar {
    font-family: 'GothamMedium';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    height: 50px;
    padding: 10px 25px 10px 25px;
  }
  .sub-navbar {
    font-family: 'GothamMedium';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 12px 20px 12px 25px;
  }
  .sub-navbar-colapse {
    margin-right: 70px;
  }
  .flag-icon {
    /* margin-right: 5px; */
    background-position: unset;
    width: 24px;
    height: 15.9px;
    line-height: 1.1em;
  }
  .mobile-sub-navbar-brand {
    font-family: 'GothamBold';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    padding: 6px 7px;
    border-radius: 3px;
  }
  .navbar-dark .navbar-brand.tx-white {
      color: #ffffff;
  }
  .navbar-dark .navbar-brand.tx-purple {
      color: #5e50a1;
  }
  .mobile-navbar.main-navbar .navbar-brand {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .mobile-navbar.main-navbar .navbar-toggler {
    padding: 0px;
  }
</style>

<style>
  .mobile-header .dropdown-toggle::after {
    margin-left: 0.2em;
    vertical-align: 0.125em;
  }
</style>

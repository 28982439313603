/* eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { isMobile } from 'mobile-device-detect';

import Shortener from './views/shortener/Shortener.vue';

import Home from './views/app/home/Home.vue';
import MobileHome from './views/mobile/home/Home.vue';

import Services from './views/app/services/Services.vue';
import MobileServices from './views/mobile/services/Services.vue';

import GuestBook from './views/app/guestbook/GuestBook.vue';
import MobileGuestBook from './views/mobile/guestbook/GuestBook.vue';

import GuestBookForm from './views/app/guestbook/GuestBookForm.vue';
import GuestBookSubmitted from './views/app/guestbook/GuestBookSubmitted.vue';

import MobileGuestBookForm from './views/mobile/guestbook/GuestBookForm.vue';
import MobileGuestBookSubmitted from './views/mobile/guestbook/GuestBookSubmitted.vue';

import Package from './views/app/package/Package.vue';
import MobilePackage from './views/mobile/package/Package.vue';

import Overdue from './views/app/overdue/Overdue.vue';
import MobileOverdue from './views/mobile/overdue/Overdue.vue';

import OverdueForm from './views/app/overdue/OverdueForm.vue';
import MobileOverdueForm from './views/mobile/overdue/OverdueForm.vue';

import OverdueSuccess from './views/app/overdue/OverdueSuccess.vue';
import MobileOverdueSuccess from './views/mobile/overdue/OverdueSuccess.vue';

import OverdueExpire from './views/app/overdue/OverdueExpire.vue';
import MobileOverdueExpire from './views/mobile/overdue/OverdueExpire.vue';

import OverdueSuccessPayment from './views/app/overdue/OverdueSuccessPayment.vue';
import MobileOverdueSuccessPayment from './views/mobile/overdue/OverdueSuccessPayment.vue';

import NotFound from './views/error/NotFound.vue';

import OrderDetail from './views/app/order/OrderDetail.vue';
import MobileOrderDetail from './views/mobile/order/OrderDetail.vue';

Vue.use(VueRouter);

const kioskWebUrl = process.env.VUE_APP_KIOSK_WEB;
const paxlokWebUrl = process.env.VUE_APP_PAXLOK_WEB;

const widthScreen = window.innerWidth
|| document.documentElement.clientWidth
|| document.body.clientWidth;

const routes = [
  {
    path: '*',
    name: 'page-not-found',
    component: NotFound,
  },
  {
    path: '/',
    name: 'index',
    component: isMobile && widthScreen < 1024 ? MobileHome : Home,
  },
  {
    path: '/services',
    name: 'services',
    component: isMobile && widthScreen < 1024 ? MobileServices : Services,
  },
  {
    path: '/guest-book',
    component: isMobile && widthScreen < 1024 ? MobileGuestBook : GuestBook,
    children: [
      {
        path: '',
        name: 'guest-book-form',
        component: isMobile && widthScreen < 1024 ? MobileGuestBookForm : GuestBookForm,
      },
      {
        path: 'submitted',
        name: 'guest-book-submitted',
        component: isMobile && widthScreen < 1024 ? MobileGuestBookSubmitted : GuestBookSubmitted,
      },
    ],
  },
  {
    path: '/package',
    name: 'package',
    component: isMobile && widthScreen < 1024 ? MobilePackage : Package,
  },
  {
    path: '/o/:code',
    name: 'shortener',
    component: Shortener,
  },
  {
    path: '/locker',
    name: 'kiosk-web-forwarder',
    beforeEnter: () => {
      window.location.href = kioskWebUrl;
    },
  },
  {
    path: '/paxelbox',
    name: 'paxlok-web-forwarder',
    beforeEnter: () => {
      window.location.href = paxlokWebUrl;
    },
  },
  {
    path: '/overduepayment',
    component: isMobile && widthScreen < 1024 ? MobileOverdue : Overdue,
    children: [
      {
        path: '',
        name: 'overdue',
        component: isMobile && widthScreen < 1024 ? MobileOverdueForm : OverdueForm,
      },
      {
        path: 'success',
        name: 'overdue-success',
        component: isMobile && widthScreen < 1024 ? MobileOverdueSuccess : OverdueSuccess,
      },
      {
        path: 'expire',
        name: 'overdue-expire',
        component: isMobile && widthScreen < 1024 ? MobileOverdueExpire : OverdueExpire,
      },
      {
        path: 'success-payment',
        name: 'overdue-success-payment',
        component: isMobile && widthScreen < 1024 ? MobileOverdueSuccessPayment : OverdueSuccessPayment,
      },
    ],
  },
  // {
  //   path: '/overduepayment',
  //   name: 'overdue-payment',
  //   component: isMobile && widthScreen < 1024 ? MobileHome : Home,
  // },
  {
    path: '/order',
    name: 'order',
    component: isMobile && widthScreen < 1024 ? MobileOrderDetail : OrderDetail,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

import Vue from 'vue';

Vue.filter('strippedTruncateContent', (content, wordLength) => {
  if (typeof content === 'undefined' || content === '' || content === null) {
    return content;
  }
  let newContent = content;
  // stripped html tag
  if (content.match(/(<([^>]+)>)/ig)) {
    newContent = content.replace(/(<([^>]+)>)/ig, '');
  }
  newContent = newContent.replace(/&nbsp;/g, ' ');

  // truncate content by length of word
  newContent = newContent.trim().split(' ').slice(0, wordLength).join(' ');
  return newContent.concat('...');
});

Vue.filter('convertEmbedYoutube', (content) => {
  if (typeof content === 'undefined' || content === '' || content === null) {
    return content;
  }

  let newContent = content;
  if (newContent.match(/<oembed/)) {
    newContent = newContent.replaceAll('<oembed', '<iframe');
    newContent = newContent.replaceAll('</oembed>', '</iframe>');
    newContent = newContent.replaceAll('url=', 'src=');
    newContent = newContent.replaceAll('watch?v=', 'embed/');
  }

  return newContent;
});

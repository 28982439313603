<template>
  <section id="services" class="services container">
    <div class="section-caption">{{ $t("paxelbox_services") }}</div>
    <div class="row"
      v-if="!loadingService && services.length > 0"
      v-vpshow-scroll
    >
      <div class="col-md-6" v-for="(service, index) in services" :key="index">
        <div class="card">
          <img
            class="card-img-top"
            v-bind:src="service.image_mobile_jpg"
            v-bind:alt="service.title"
          />
          <div class="card-body">
            <h5 class="card-title">{{ service.title }}</h5>
            <div class="card-text">{{ service.description | strippedTruncateContent(15) }}</div>
          </div>
          <div class="card-footer">
            <router-link tag="a" :to="`/services/#` + service.slug" class="bg-purple read-more-btn">
              {{ $t("read_more") }}
              <span class="arrow-right">
                <img src="@/assets/icons/arrow-right-white.svg" alt="Paxel" />
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" v-else-if="!loadingService && services.length == 0"></div>
    <PaxelLoader :loading="loadingService"></PaxelLoader>
  </section>
</template>

<script>
import PaxelLoader from '@/components/PaxelLoader.vue';

export default {
  name: 'MobileServices',
  components: {
    PaxelLoader,
  },
  data() {
    return {
      services: [],
      loadingService: true,
    };
  },
  mounted() {
    this.$store.dispatch('fetchPaxelBoxServices', {
      language: this.$store.getters.getLanguage,
      limit: 3,
    });
  },
  created() {
    this.$store.watch(
      (state) => state.paxelBoxServices,
      (newValue) => {
        this.loadingService = true;
        this.services = newValue;
        this.loadingService = false;
      },
    );
    this.$store.watch(
      (state) => state.language,
      (newValue) => {
        this.$store.dispatch('fetchPaxelBoxServices', { language: newValue, limit: 3 });
      },
    );
  },
};
</script>

<style scoped>
.mobile-home section .section-caption {
  width: 135px;
}
.card {
  border: none;
  border-radius: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.card:hover {
  z-index: 1000;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}
.card > img {
  height: 252px;
  border-radius: 20px 20px 0px 0px;
  -webkit-transition: all 4s ease;
  -moz-transition: all 4s ease;
  -o-transition: all 4s ease;
  -ms-transition: all 4s ease;
  transition: all 4s ease;
}
.card:hover > img {
  opacity: 0.9;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.card-body {
  height: 150px;
  padding: 16px 36px 16px 42px;
  overflow: hidden;
  background-color: #f9f9fb;
}
.card-body .card-title {
  font-family: "GothamMedium";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.02px;
  color: #414042;
  width: 150px;
}
.card-body .card-text {
  font-family: "GothamBook";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: #575758;
}
.card-footer:last-child {
  border: none;
  padding: 16px 36px 32px 42px;
  background-color: #f9f9fb;
  border-radius: 0 0 20px 20px;
}
.card-footer a {
  font-family: "GothamBold";
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border: none;
  padding-top: 18px;
  padding-bottom: 18px;
  letter-spacing: 0.39px;
  border-radius: 0.25rem;
  text-align: center;
  display: block;
  width: 100%;
  color: #ffffff;
}
.card-footer a.read-more-btn:hover {
  background-color: #524494;
}
.card-footer a.read-more-btn:focus {
  border: none;
}
.card-footer a .arrow-right {
  float: right;
  padding-right: 20px;
  -webkit-transform: perspective(1px) translateZ(0);
  -moz-transform: perspective(1px) translateZ(0);
  -ms-transform: perspective(1px) translateZ(0);
  -o-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}
.card-footer a.read-more-btn:hover .arrow-right {
  -webkit-animation-name: wobble-horizontal-on-hover;
  animation-name: wobble-horizontal-on-hover;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.card-footer img {
  vertical-align: inherit;
}
.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}
@-webkit-keyframes wobble-horizontal-on-hover {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes wobble-horizontal-on-hover {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
</style>

<!-- eslint-disable -->
<template>
    <b-container class="text-12">
        <div class="card border-0">
            <img src="@/assets/images/img-nocontent.svg" class="img-fluid mx-auto d-block">
        </div>
        <div class="py-3 text-center">
      <h5 class="text-purple text-gotham-bold text-21">Link Kadaluwarsa</h5>
    </div>
    <div class="text-center">
      <p style="padding-left: 10px; padding-right: 10px;">
        Link yang kamu klik sudah ga berlaku.
      </p>
    </div>
    <div class="text-center pb3">
      <p style="padding-left: 10px; padding-right: 10px;">
        Butuh bantuan? Silakan hubungi <span class="text-gotham-bold text-purple" style="cursor: pointer" @click="contactHera(heraUrl)">HERA</span>
      </p>
    </div>
    <div class="text-center text-14">
      <p style="padding-left: 10px; padding-right: 10px;" class="text-gotham-bold">
        Download Aplikasi Paxel
      </p>
    </div>
    <div>
      <div class="row">
        <div class="col text-center">
            <div>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.paxel&amp;referrer=utm_source%3Dwebrevamp%26utm_medium%3Dbadge">
                  <img
                    src="@/assets/images/download-play.png"
                    width="160"
                    alt="Paxel App"
                    class="img-fluid mt-2"
                  >
                </a>
            </div>
            <div>
                <a target="_blank" href="https://apps.apple.com/app/apple-store/id1372668166?pt=118967865&amp;ct=webrevamp&amp;mt=8">
                <img
                  src="@/assets/images/download-app.png"
                  width="160"
                  alt="Paxel App"
                  class="img-fluid mt-2"
                >
                </a>
            </div>
        </div>
      </div>
    </div>
    </b-container>
</template>

<script>
import LocalStorage from '@/services/localstorage';

export default {
  data() {
    return {
      heraUrl: process.env.VUE_APP_HERA_LINK,
    };
  },
  mounted() {
    // remove localstorage overdue
    LocalStorage.removeOverdue();
  },
  methods: {
    contactHera(url) {
      window.location.href = url;
    },
  },
};
</script>

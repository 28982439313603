import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LocalStorage from '@/services/localstorage';

Vue.use(VueI18n);

const messages = {
  id: {
    our_services: 'Layanan Kami',
    news_and_promos: 'Berita & Promo',
    partnership: 'Kemitraan',
    about_partnership: 'Tentang Kemitraan',
    services: 'Layanan',
    location: 'Lokasi',
    contact: 'Kontak',
    content: 'Isi',
    paxelbox_services: 'Layanan PaxelBox',
    read_more: 'Baca Lebih Lanjut',
    location_caption: 'Dimana Kita Temukan PaxelBox?',
    location_description: 'Anda dapat menemukan PaxelBox di lebih dari 260 lokasi di sekitar Jabodetabek, ',
    and_still_growing: 'dan masih terus berkembang!',
    contact_caption: 'Keunggulan PaxelBox',
    contact_value_1: 'Notifikasi<br>Otomatis',
    contact_value_2: 'Bisa di Akses<br>24/7',
    contact_value_3: 'Beragam Ukuran<br>S, M, L, XL',
    contact_value_4: 'Lebih Aman<br>dan Privasi',
    contact_value_5: 'Tanpa Kontak<br>Fisik',
    contact_value_6: 'Mudah<br>Digunakan',
    contact_form_caption: 'Tertarik untuk Bekerja Sama?',
    contact_form_description: 'Bawa PaxelBox lebih dekat kepadamu',
    fill_form: 'Isi Form',
    choose_business_type: 'Pilih Tipe Bisnis',
    individu: 'Individu',
    business: 'Bisnis',
    about_paxel: 'Tentang Paxel',
    support: 'Bantuan',
    terms_and_conditions: 'Syarat & Ketentuan',
    privacy_policy: 'Kebijakan Privasi',
    follow_us: 'Ikuti Kami',
    download_our_app: 'Download Aplikasi Paxel',
    how_to_use: 'Cara Penggunaan',
    download_paxel_app: 'Unduh Aplikasi Paxel',
    guest_book: 'Buku Tamu',
    sender_name: 'Nama Pengirim',
    sender_contact: 'Kontak Pengirim',
    email_address: 'Alamat Email',
    what_do_you_want: 'Apa yang Anda inginkan',
    paxelbox_locker: 'PaxelBox Loker',
    about_services_and_partnership_with_paxelbox: 'Tentang layanan dan kerjasama dengan PaxelBox',
    advertise_on_paxelbox: 'Beriklan di PaxelBox',
    place_your_ad_on_paxelbox: 'Pasang iklan Anda di PaxelBox',
    message: 'Pesan',
    guest_book_term_check: 'Dengan ini saya menyetujui <a href={url}>Syarat dan Ketentuan</a> yang berlaku dan setuju untuk mengirimkan data di atas untuk digunakan oleh Paxel.',
    send_and_download_white_paper: 'Kirim & Download White Paper',
    enter_sender_name: 'Masukkan nama pengirim',
    enter_sender_contact: 'Masukkan kontak pengirim',
    enter_email_address: 'Masukkan alamat email',
    enter_your_message: 'Masukkan pesan Anda',
    should_not_empty: 'tidak boleh kosong',
    you_should_choose_one: 'Anda harus memilih salah satu',
    you_should_accept_terms_and_conditions: 'Anda harus menerima Syarat dan Ketentuan',
    not_valid: 'tidak valid',
    between_length: 'harus diawali 0 dan antara {min} sampai {max} angka',
    back_to_paxelbox: 'Kembali Ke PaxelBox',
    something_wrong: 'Terjadi kesalahan server, silahkan hubungi Customer Service kami',
    data_package: 'Paket Data',
    delivery_provider: 'Jasa Pengiriman',
    recipient_name: 'Nama Penerima',
    recipient_mobile_number: 'Telepon Penerima',
    locker_location: 'Lokasi Loker',
    package_size: 'Ukuran Paket',
    package_photo: 'Foto Paket',
    select_photo: 'Pilih Photo',
    change_photo: 'Ubah Photo',
    preview_photo: 'Lihat Photo',
    package_photo_and_airwaybill: 'Foto paket dan airwaybill',
    slider_verification: 'Verifikasi Slider',
    confirm: 'Konfirmasi',
    enter_recipient_name: 'Masukkan nama penerima',
    enter_recipient_mobile_number: 'Masukkan telepon penerima',
    choose_package_size: 'Pilih ukuran paket',
    etc: 'dll',
    ex: 'Contoh',
    swipe_to_the_righ_side: 'Geser ke sebelah kanan',
    verification_success: 'Verifikasi sukses',
    please_swipe_for_verification: 'Mohon menggeser untuk verifikasi',
    your_data_has_been_submitted: 'Data Anda telah dikirim',
    your_white_paper_download: 'White Paper Anda akan mulai segera diunduh, Terima kasih atas minat Anda pada Paxelbox',
    your_request_could_not_be_found: 'Permintaan Anda tidak dapat ditemukan. Silakan periksa alamat shortener anda',
    your_page_could_not_be_found: 'Halaman yang Anda maksud tidak dapat ditemukan. Silakan periksa alamat url anda',
    no_locker_available: 'Tidak ada locker yang tersedia',
    track_shipment: 'Cek Resi',
    check_rates: 'Cek Ongkir',
  },
  en: {
    our_services: 'Our Services',
    news_and_promos: 'News & Promo',
    partnership: 'Partnership',
    about_partnership: 'About Partnership',
    services: 'Services',
    location: 'Location',
    contact: 'Contact',
    content: 'Content',
    paxelbox_services: 'PaxelBox Services',
    read_more: 'Read More',
    location_caption: 'Where We Find PaxelBox?',
    location_description: 'You can find PaxelBox on more than 260 locations around Jabodetabek, ',
    and_still_growing: 'and still growing!',
    contact_caption: 'PaxelBox Value',
    contact_value_1: 'Automatic<br>Notification',
    contact_value_2: 'Accessible<br>24/7',
    contact_value_3: 'Various Size<br>S, M, L, XL',
    contact_value_4: 'More Safe<br>and Private',
    contact_value_5: 'No Physical<br>Contact',
    contact_value_6: 'Easy<br>to Use',
    contact_form_caption: 'Interested for Partnership?',
    contact_form_description: 'Bring PaxelBox closer to you',
    fill_form: 'Fill Form',
    choose_business_type: 'Choose Business Type',
    individu: 'Individu',
    business: 'Business',
    about_paxel: 'About Paxel',
    support: 'Support',
    terms_and_conditions: 'Terms & Conditions',
    privacy_policy: 'Privacy Policy',
    follow_us: 'Follow Us',
    download_our_app: 'Download Our App',
    how_to_use: 'How To Use',
    download_paxel_app: 'Download Paxel App',
    guest_book: 'Guest Book',
    sender_name: 'Sender Name',
    sender_contact: 'Sender Contact',
    email_address: 'Email Address',
    what_do_you_want: 'What do You want',
    paxelbox_locker: 'PaxelBox Locker',
    about_services_and_partnership_with_paxelbox: 'About services and partnership with PaxelBox',
    advertise_on_paxelbox: 'Advertise on PaxelBox',
    place_your_ad_on_paxelbox: 'Place Your ads on PaxelBox',
    message: 'Message',
    guest_book_term_check: 'I hereby agree to the applicable <a href={url}>Terms and Conditions</a> and agree to submit the above data for use by Paxel.',
    send_and_download_white_paper: 'Send & Download White Paper',
    enter_sender_name: 'Enter sender name',
    enter_sender_contact: 'Enter sender contact',
    enter_email_address: 'Enter email address',
    enter_your_message: 'Enter Your message',
    should_not_empty: 'should not empty',
    you_should_choose_one: 'You should choose one',
    you_should_accept_terms_and_conditions: 'You should accept Terms and Conditions',
    not_valid: 'not valid',
    between_length: 'should started 0 and between {min} to {max} numbers',
    back_to_paxelbox: 'Back to PaxelBox',
    something_wrong: 'A server error occurred, please contact our Customer Service',
    data_package: 'Data Package',
    delivery_provider: 'Delivery Provider',
    recipient_name: 'Recipient Name',
    recipient_mobile_number: 'Recipient Mobile Number',
    locker_location: 'Locker Location',
    package_size: 'Package Size',
    package_photo: 'Package Photo',
    select_photo: 'Select Photo',
    change_photo: 'Change Photo',
    preview_photo: 'Preview Photo',
    package_photo_and_airwaybill: 'Package photo and airwaybill',
    slider_verification: 'Slider Verification',
    enter_recipient_name: 'Enter recipient name',
    enter_recipient_mobile_number: 'Enter recipient mobile number',
    choose_package_size: 'Choose package size',
    confirm: 'Confirm',
    etc: 'etc',
    ex: 'Ex',
    swipe_to_the_righ_side: 'Swipe to the right side',
    verification_success: 'Verification Success',
    please_swipe_for_verification: 'Please swipe for verification',
    your_data_has_been_submitted: 'Your data has been submited',
    your_white_paper_download: 'Your White Paper download will begin shortly, Thank you for your interest in Paxelbox',
    your_request_could_not_be_found: 'Your request could not be found. Please check your shortener address',
    your_page_could_not_be_found: 'Your request page could not be found. Please check your url address',
    no_locker_available: 'No locker available',
    track_shipment: 'Track Shipment',
    check_rates: 'Check Rates',
  },
};

const i18n = new VueI18n({
  locale: LocalStorage.getLanguage(), // set locale
  fallbackLocale: 'id', // set fallback locale
  messages, // set locale messages
});

export default i18n;

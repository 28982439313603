<template>
  <div class="desktop-home">
    <PaxelBox />
    <Services />
    <Location />
    <Contact />
  </div>
</template>

<script>
import LocalStorage from '@/services/localstorage';
import PaxelBox from './PaxelBox.vue';
import Services from './Services.vue';
import Location from './Location.vue';
import Contact from './Contact.vue';

export default {
  name: 'DesktopHome',
  components: {
    PaxelBox,
    Services,
    Location,
    Contact,
  },
  created() {
    setTimeout(() => this.$root.scrollToAnchor(this.$route.hash), 2000);
  },
  mounted() {
    // checking query paramater overduepayment
    const paramOrderId = this.$route.query.orderid;
    if (paramOrderId !== undefined) {
      this.$store.dispatch('getOverdueDropoffDetail', paramOrderId)
        .then((response) => {
          const { overdueStatus } = response;
          switch (overdueStatus) {
            case 'need_to_pay':
              this.redirectOverdue('form');
              break;
            case 'none':
              this.redirectOverdue('success');
              break;
            case 'expired':
              this.redirectOverdue('expire');
              break;
            default:
              this.redirectOverdue();
              break;
          }
        })
        .catch(() => {
          this.redirectOverdue();
        });
    }
    // checking localstorage overdue
    if (LocalStorage.getOverdue() !== null) {
      LocalStorage.removeOverdue();
    }
  },
  methods: {
    redirectOverdue(page) {
      let urlPage = '/';
      if (page === 'success') {
        urlPage = '/overduepayment/success';
      } else if (page === 'expire') {
        urlPage = '/overduepayment/expire';
      } else if (page === 'form') {
        urlPage = '/overduepayment';
      }
      this.$router
        .push({ path: urlPage })
        .then(() => { this.$router.go(); });
    },
  },
};
</script>
<style>
.desktop-home section {
  margin-bottom: 100px;
}
.desktop-home section .section-caption {
  font-family: "GothamBold";
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 48px;
  letter-spacing: normal;
  margin-bottom: 40px;
  color: #5e51a1;
}
.desktop-home .services .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}
</style>

<!-- eslint-disable -->
<template>
  <div class=" w-50 m-width text-14 container">
    <section id="header" class="desktop-header fixed-top  w-50 m-width text-14 container">
      <div>
        <b-navbar class="shadow-sm bg-white" toggleable="lg" type="dark">
          <b-navbar-brand style="display: flex;align-items: center;">
            <span id="back-userapp" @click="backToUA()">
              <svg class="" viewBox="0 0 24 24" style="
								fill: #5e51a1;
								width: 1.5rem;
								height: 1.5rem;
								">
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
              </svg>
            </span>
            <span class="header-title">{{ language == 'en' ? 'Order Details' : 'Detail Order' }} </span>
          </b-navbar-brand>
        </b-navbar>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'HeaderNav',
  methods: {
    backToUA() {
      if (typeof MobileJS != "undefined") {
        MobileJS.closePaxelWebview();
      } else if (typeof webkit != "undefined") {
        try {
          webkit.messageHandlers.closePaxelWebview.postMessage(
            "closePaxelWebview"
          );
        } catch (err) {
          console.log("The native context does not exist yet");
        }
      } else {
        this.$router.go("-1");
      }
    }
  },
};
</script>

<style>
.header-title {
  margin-left: 40px;
  text-align: center;
  align-items: center;
  color: #424143;
  font-size: 16px;
  font-weight: bold;
}
</style>

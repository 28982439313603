<template>
  <section id="contact">
    <div class="container">
      <div class="contact-sections">
        <div class="contact-caption">
          {{ $t('contact_caption') }}
        </div>
      </div>
      <b-row class="contact-feature-img">
        <b-col><img src="@/assets/icons/bell.svg" alt="Paxel" /></b-col>
        <b-col><img src="@/assets/icons/clock.svg" alt="Paxel" /></b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="contact-feature-text" v-html="$t('contact_value_1')"></p>
        </b-col>
        <b-col>
          <p class="contact-feature-text" v-html="$t('contact_value_2')"></p>
        </b-col>
      </b-row>
      <b-row class="contact-feature-img">
        <b-col><img src="@/assets/icons/package.svg" alt="Paxel" /></b-col>
        <b-col><img src="@/assets/icons/shield.svg" alt="Paxel" /></b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="contact-feature-text" v-html="$t('contact_value_3')"></p>
        </b-col>
        <b-col>
          <p class="contact-feature-text" v-html="$t('contact_value_4')"></p>
        </b-col>
      </b-row>
      <b-row class="contact-feature-img">
        <b-col><img src="@/assets/icons/hand.svg" alt="Paxel" /></b-col>
        <b-col><img src="@/assets/icons/touch.svg" alt="Paxel" /></b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="contact-feature-text" v-html="$t('contact_value_5')"></p>
        </b-col>
        <b-col>
          <p class="contact-feature-text" v-html="$t('contact_value_6')"></p>
        </b-col>
      </b-row>
    </div>
    <div class="contact-form">
      <div class="container">
        <div class="contact-header">
          <div class="contact-title">
            {{ $t("contact_form_caption") }}
          </div>
          <div class="contact-arrow">
            <svg width="1em" height="1em" viewBox="0 0 16 16"
              class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0
              .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
        </div>
        <div class="contact-text">
          {{ $t("contact_form_description") }}
        </div>
        <div class="contact-select">
          <VueSelect
            :placeholder="$t('choose_business_type')"
            :clearable="false"
            :searchable="false"
            label="label"
            @input="setSelectedBusinessType"
            :options="[
              { label: $t('individu'), code: 'Individu' },
              { label: $t('business'), code: 'Business' }
            ]"
          >
          </VueSelect>
        </div>
        <div class="contact-button">
          <router-link
            tag="a"
            :to="{ name: 'guest-book-form', query: { business_type: selectedBusinesType }}"
            class="bg-purple fill-form-btn"
            :class="{ disabled: selectedBusinesType == '' }" exact>
            {{ $t("fill_form") }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'MobileContact',
  components: { VueSelect },
  methods: {
    setSelectedBusinessType(value) {
      this.selectedBusinesType = value.code;
    },
  },
  data() {
    return {
      selectedBusinesType: '',
    };
  },
};
</script>

<style>
.mobile-home .vs__open-indicator {
  fill: #ffb200;
}
.mobile-home .vs__selected-options {
  height: 35px;
}
.mobile-home .vs__search {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #424143;
}
</style>

<style scoped>
  .contact-sections {
    height: 80px;
    margin: 0 97px 20px 10px;
    font-family: "GothamBold";
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 0.24px;
    color: #5e50a1;
  }
  .contact-feature-text {
    line-height: 1.86;
    text-align: center;
  }
  .contact-feature-img {
    text-align: center;
  }
  .contact-form {
    margin-bottom: 50px;
    margin-top: 50px;
    padding-top: 32px;
    padding-bottom: 26px;
    background-color: #f9f9fb;
  }
  .contact-header {
    font-family: "GothamMedium";
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: normal;
    color: #5e51a1;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 10px;
  }
  .contact-title {
    width: 100%;
    padding-right: 70px;
  }
  .contact-arrow {
    color: #5e50a1;
    align-self: flex-end;
    padding-bottom: 5px;
  }
  .contact-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 27px;
    letter-spacing: normal;
    color: #424143;
    padding-bottom: 9px;
    border-bottom: solid 0.5px #d8d8d8;
  }
  .contact-select {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #ffffff;
  }
  .contact-button a {
    font-family: "GothamBold";
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    border: none;
    padding-top: 14px;
    padding-bottom: 14px;
    letter-spacing: normal;
    border-radius: 0.25rem;
    text-align: center;
    display: block;
    width: 100%;
    color: #ffffff;
  }
  .contact-button a.fill-form-btn:hover {
    background-color: #524494;
  }
  .contact-button a.fill-form-btn:focus {
    border: none;
  }
</style>

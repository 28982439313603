/* eslint-disable */

import RevampAPIServices from '@/services/revampApi';
import PaymentAPIServices from '@/services/paymentApi';
import TokenPaymentAPIServices from '@/services/tokenPaymentApi';
import LocalStorage from '@/services/localstorage';

const v1 = 'api/microsite/v1';
const paxelBoxV1 = 'api/paxelbox/v1';
const token = 'auth/token';
const payment = 'api/v1/order';

// actions
const actions = {
  changeLanguage({ commit }, data) {
    commit('setLanguage', data);
  },
  fetchPaxelBoxBanners({ commit }, data) {
    RevampAPIServices.post(`/${v1}/microsite-banner/${data.language}/find-all-published-with-paging/${data.limit}`)
      .then((response) => {
        const responseData = response.data;
        if (responseData.code === 200) {
          commit('setPaxelBoxBanners', responseData.data);
        } else {
          commit('setPaxelBoxBanners', []);
        }
      })
      .catch(() => {
        commit('setPaxelBoxBanners', []);
      });
  },
  fetchPaxelBoxServices({ commit }, data) {
    RevampAPIServices.post(`/${v1}/microsite-service/${data.language}/find-all-published-with-paging/${data.limit}`)
      .then((response) => {
        const responseData = response.data;
        if (responseData.code === 200) {
          commit('setPaxelBoxServices', responseData.data);
        } else {
          commit('setPaxelBoxServices', []);
        }
      })
      .catch(() => {
        commit('setPaxelBoxServices', []);
      });
  },
  sendGuestBook({ commit }, data) {
    return new Promise((resolve, reject) => {
      RevampAPIServices.post(`/${v1}/microsite-guest-book/id/guest-book`, data)
        .then((response) => {
          const responseData = response.data;
          commit('setGuestBook', responseData.data);
          resolve(response);
        })
        .catch((error) => {
          commit('setGuestBook', []);
          reject(error);
        });
    });
  },
  fetchAvailableLockerSize({ commit }, id) {
    RevampAPIServices.get(`/${v1}/microsite-locker-dispatcher/locker-size/${id}`)
      .then((response) => {
        const responseData = response.data;
        if (responseData.code === 200) {
          commit('setAvailableLockers', responseData.data);
        } else {
          commit('setAvailableLockers', []);
        }
      })
      .catch(() => {
        commit('setAvailableLockers', []);
      });
  },
  sendPackageData({ commit }, data) {
    RevampAPIServices.post(`/${v1}/microsite-locker-dispatcher`, data)
      .then((response) => {
        const responseData = response.data;
        if (responseData.code === 200) {
          commit('setPackageLocker', responseData.data);
        } else {
          commit('setPackageLocker', {});
        }
      })
      .catch(() => {
        commit('setPackageLocker', {});
      });
  },
  getShortener({ commit }, code) {
    RevampAPIServices.get(`/${paxelBoxV1}/shortening-url/${code}`)
      .then((response) => {
        const responseData = response.data;
        if (responseData.code === 200) {
          commit('setShortener', responseData.data);
        } else {
          commit('setShortener', {});
        }
      })
      .catch(() => {
        commit('setShortener', {});
      });
  },
  getOverdueDropoffDetail({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      RevampAPIServices.get(`/${v1}/overdue-dropoff/check/${orderId}`)
        .then((response) => {
          const responseData = response.data;
          if (responseData.code === 200) {
            commit('setOverdueDropOffDetail', responseData.data);
            LocalStorage.setOverdue(JSON.stringify(responseData.data));
            resolve(responseData.data);
          } else {
            commit('setOverdueDropOffDetail', {});
            resolve(responseData.data);
          }
        })
        .catch((error) => {
          commit('setOverdueDropOffDetail', {});
          LocalStorage.removeOverdue();
          reject(error);
        });
    });
  },
  sendOverdueDropoff({ commit }, data) {
    return new Promise((resolve, reject) => {
      RevampAPIServices.post(`/${v1}/overdue-dropoff/payment-log/${data.orderId}`, data.formdata)
        .then((response) => {
          const responseData = response.data;
          if (responseData.code === 200) {
            commit('setOverdueDropOff', response);
            resolve(response);
          } else {
            commit('setOverdueDropOff', {});
            resolve(response);
          }
        })
        .catch((error) => {
          commit('setOverdueDropOff', {});
          reject(error);
        });
    });
  },
  getTokenPayment() {
    return new Promise((resolve, reject) => {
      TokenPaymentAPIServices.get(`/${token}/generate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      PaymentAPIServices.post(`/${payment}/multiple/locker?access_token=${data.token}`, data.formdata)
        .then((response) => {
          const responseData = response.data;
          if (responseData.code === 200) {
            resolve(responseData.data);
          } else {
            resolve(responseData.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrderDetail({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      RevampAPIServices.get(`/${v1}/order/${orderId}`)
        .then((response) => {
          const responseData = response.data;
          if (responseData.code === 200) {
          
            resolve(responseData.data);
          } else {
            resolve(responseData.data);
          }
        })
        .catch((error) => {
          commit('setOverdueDropOffDetail', {});
          LocalStorage.removeOverdue();
          reject(error);
        });
    });
  },
  validateUser({ commit }, param) {
    return new Promise((resolve, reject) => {
      RevampAPIServices.get(`/api/webview/v1/members/auth?token=${param.token}`)
        .then((response) => {
          commit('setLanguage', response.data.data.language);
          resolve(response);
        })
        .catch((error) => {
          console.log("ERROR")
          reject(error);
        }).finally(() => {
        });
    });
  },
};

export default actions;
